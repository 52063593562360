import { createAction, props } from '@ngrx/store';
import { Notification } from 'src/app/services/interface';

export const NOTIFY = '[Global] Notify';
export const CLEAR_NOTIFICATION = '[Global] Clear Notification';
export const NO_OP_ACTION = '[Global] No Op Action';

export const notify = createAction(
  NOTIFY,
  props<{
    notification: Notification;
  }>()
);

export const clearNotification = createAction(
  CLEAR_NOTIFICATION,
  props<{
    id: string;
  }>()
);

export const noOpAction = createAction(
  NO_OP_ACTION,
);
