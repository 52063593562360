import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';


import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Application: environment.application
      }
    });
    return next.handle(req);
  }
}
