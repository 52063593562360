import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Survey, SurveyOption } from 'src/app/services/surveys.service';

export const LOAD_SURVEYS = '[Survey] Load Surveys';
export const LOAD_SURVEYS_SUCCESS = '[Survey] Load Surveys Success';
export const LOAD_SURVEYS_FAILURE = '[Survey] Load Surveys Failure';

export const ANSWER_SURVEY = '[Survey] Answer Survey';
export const ANSWER_SURVEY_SUCCESS = '[Survey] Answer Survey Success';
export const ANSWER_SURVEY_FAILURE = '[Survey] Answer Survey Failure';

export const loadSurveys = createAction(LOAD_SURVEYS);

export const loadSurveysSuccess = createAction(
  LOAD_SURVEYS_SUCCESS,
  props<{
    surveys: Survey[];
  }>()
);

export const loadSurveysFailure = createAction(
  LOAD_SURVEYS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const answerSurvey = createAction(
  ANSWER_SURVEY,
  props<{
    survey: Survey;
    options: SurveyOption[];
  }>()
);

export const answerSurveySuccess = createAction(
  ANSWER_SURVEY_SUCCESS,
  props<{
    survey: Survey;
    options: SurveyOption[];
  }>()
);

export const answerSurveyFailure = createAction(
  ANSWER_SURVEY_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);
