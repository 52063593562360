import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsConnected } from '../store/selectors/auth.selectors';
import { AppState } from '../store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsConnectedGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isConnected$: Observable<boolean> = this.store.select(selectIsConnected);
    return isConnected$.pipe(map((isConnected: boolean) => isConnected ? isConnected : this.router.createUrlTree(['/login'])));
  }
}
