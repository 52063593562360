import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  loadChatFailure,
  loadChatsFailure,
  loadChatsSuccess,
  loadChatSuccess,
  loadOperatorsFailure,
  loadOperatorsSuccess,
  LOAD_CHAT,
  LOAD_CHATS,
  LOAD_OPERATORS,
  newChatFailure,
  newChatSuccess,
  NEW_CHAT,
  NEW_CHAT_SUCCESS,
  RECEIVED_CHAT,
  sendMessageFailure,
  sendMessageSuccess,
  SEND_MESSAGE,
} from '../actions/chats.actions';
import { Chat, ChatService, Message } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { User } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { selectCurrentChat } from '../selectors/chats.selectors';
import { selectUser } from '../selectors/auth.selectors';

@Injectable()
export class ChatsEffects {
  loadChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_CHATS),
      concatLatestFrom(() => this.store.select(selectUser)),
      exhaustMap(([,user]: [unknown, User]) =>
        this.meService.allChats().pipe(
          map((chats: Chat[]) => {
            return chats.map((chat: Chat) => {
              chat.discussingWith = chat.assignee.uuid === user.uuid ? 'initiator' : 'assignee'
              return chat
            })
          }),
          map((chats: Chat[]) => loadChatsSuccess({ chats })),
          catchError((error: any) => of(loadChatsFailure({ error }))),
        )
      )
    );
  });

  loadChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_CHAT),
      concatLatestFrom(() => this.store.select(selectUser)),
      exhaustMap(([{ uuid }, user]: [any, User]) => {
        return this.chatService.one(uuid).pipe(
          map((chat: Chat) => {
            chat.discussingWith = chat.assignee.uuid === user.uuid ? 'initiator' : 'assignee'
            return chat
          }),
          map((chat: Chat) => loadChatSuccess({ chat, user })),
          catchError((error: any) => of(loadChatFailure({ error }))),
        );
      })
    );
  });

  sendMessage$ = createEffect(() =>{
    return this.actions$.pipe(
      ofType(SEND_MESSAGE),
      concatLatestFrom(() => this.store.select(selectCurrentChat)),
      exhaustMap(([{ data }, chat]: any) => {
        return this.chatService.sendMessage(chat, data).pipe(
          map((message: Message) => sendMessageSuccess({ message })),
          catchError((error: any) => of(sendMessageFailure({ error }))),
        );
      })
    );
  });

  loadOperators$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_OPERATORS),
      exhaustMap(() => {
        return this.chatService.operators().pipe(
          map((operators: User[]) => loadOperatorsSuccess({ operators })),
          catchError((error: any) => of(loadOperatorsFailure({ error }))),
        );
      })
    );
  });

  newChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NEW_CHAT),
      exhaustMap(({ data }) => {
        return this.chatService.new(data).pipe(
          map((chat: Chat) => newChatSuccess({ chat })),
          catchError((error: any) => of(newChatFailure({ error }))),
        );
      })
    );
  });

  receivedChatJoinRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RECEIVED_CHAT),
      tap(({ chat }: { chat: Chat }) => {
        this.socketService.io.emit('subscribe', { room: chat.uuid });
      })
    );
  }, {
    dispatch: false
  });

  newChatSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NEW_CHAT_SUCCESS),
      tap(({ chat }: { chat: Chat}) => {
        this.socketService.io.emit('subscribe', { room: chat.uuid });
        this.socketService.io.emit('chat:new', { chat });
        this.router.navigate(['/chats']);
      })
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private chatService: ChatService,
    private meService: MeService,
    private socketService: SocketService,
    private toastController: ToastController,
    private store: Store,
    private router: Router,
  ) { }
}
