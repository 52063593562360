import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './auth.service';
import { Domain } from './domain.service';

export interface Survey {
  uuid: string;
  domain: Domain;
  creator: User;
  question: string;
  multiple_answers: boolean;
  created_at: string;
  updated_at: string;
  options: SurveyOption[];
  current_user_answers: SurveyOption[];
  start_at: string;
  end_at: string;
  survey_group_id: string | null;
  group: any;
  status?: string;
  is_reanswerable?: boolean;
}

export interface SurveyOption {
  uuid: string;
  survey_id: string;
  option: string;
  created_at: string;
  updated_at: string;
  isSelected?: boolean;
  answers_count?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  all({ active, past }: { active: boolean, past: boolean }) {
    let url = `${environment.apiUrl}/domain/surveys?`;
    if (active) {
      url+= `&active`;
    }
    if (past) {
      url+= `&past`;
    }
    return this.httpClient.get<Survey[]>(url);
  }

  one(uuid: string): Observable<Survey> {
    return this.httpClient.get<Survey>(`${environment.apiUrl}/survey/${uuid}`);
  }

  answer({ survey, options }: { survey: Survey, options: SurveyOption[] }) {
    return this.httpClient.post(`${environment.apiUrl}/surveys/${survey.uuid}/vote`, {
      options: options.map((option: SurveyOption) => option.uuid)
    });
  }
}
