import { Directive, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[mvpSetValue]'
})
export class SetValueDirective implements OnChanges {

  @Input() mvpSetValue: string;

  constructor(private ngControl: NgControl) { }

  ngOnChanges() {
    this.ngControl.control.setValue(this.mvpSetValue, { emitEvent: false });
  }

}
