import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Article } from 'src/app/services/article.service';
import { AuthResponse, User } from 'src/app/services/auth.service';
import { Domain } from 'src/app/services/domain.service';
import { Question } from 'src/app/services/question.service';
import { SearchResult } from 'src/app/services/search.service';

export const LOAD_MOST_VIEWED_QUESTIONS = '[Files] Load Most Viewed Questions';
export const LOAD_MOST_VIEWED_QUESTIONS_SUCCESS = '[Files] Load Most Viewed Questions Success';
export const LOAD_MOST_VIEWED_QUESTIONS_FAILURE = '[Files] Load Most Viewed Questions Failure';

export const LOAD_MORE_MOST_VIEWED_QUESTIONS = '[Files] Load More Most Viewed Questions';
export const LOAD_MORE_MOST_VIEWED_QUESTIONS_SUCCESS = '[Files] Load More Most Viewed Questions Success';
export const LOAD_MORE_MOST_VIEWED_QUESTIONS_FAILURE = '[Files] Load More Most Viewed Questions Failure';

export const LOAD_MOST_VIEWED_ARTICLES = '[Files] Load Most Viewed Articles';
export const LOAD_MOST_VIEWED_ARTICLES_SUCCESS = '[Files] Load Most Viewed Articles Success';
export const LOAD_MOST_VIEWED_ARTICLES_FAILURE = '[Files] Load Most Viewed Articles Failure';

export const LOAD_MORE_MOST_VIEWED_ARTICLES = '[Files] Load More Most Viewed Articles';
export const LOAD_MORE_MOST_VIEWED_ARTICLES_SUCCESS = '[Files] Load More Most Viewed Articles Success';
export const LOAD_MORE_MOST_VIEWED_ARTICLES_FAILURE = '[Files] Load More Most Viewed Articles Failure';

export const LOAD_LATEST_ARTICLES = '[Files] Load Latest Articles';
export const LOAD_LATEST_ARTICLES_SUCCESS = '[Files] Load Latest Articles Success';
export const LOAD_LATEST_ARTICLES_FAILURE = '[Files] Load Latest Articles Failure';

export const LOAD_MORE_LATEST_ARTICLES = '[Files] Load More Latest Articles';
export const LOAD_MORE_LATEST_ARTICLES_SUCCESS = '[Files] Load More Latest Articles Success';
export const LOAD_MORE_LATEST_ARTICLES_FAILURE = '[Files] Load More Latest Articles Failure';

export const LOAD_PINNED_ARTICLES = '[Files] Load Pinned Articles';
export const LOAD_PINNED_ARTICLES_SUCCESS = '[Files] Load Pinned Articles Success';
export const LOAD_PINNED_ARTICLES_FAILURE = '[Files] Load Pinned Articles Failure';

export const SEARCH = '[Files] Search';
export const SEARCH_SUCCESS = '[Files] Search Success';
export const SEARCH_FAILURE = '[Files] Search Failure';
export const CLEAR_SEARCH = '[Files] Clear Search';

export const loadPinnedArticles = createAction(
  LOAD_PINNED_ARTICLES,
);

export const loadPinnedArticlesSuccess = createAction(
  LOAD_PINNED_ARTICLES_SUCCESS,
  props<{
    articles: Article[];
  }>()
);

export const loadPinnedArticlesFailure = createAction(
  LOAD_PINNED_ARTICLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);
///
export const loadMostViewedQuestions = createAction(
  LOAD_MOST_VIEWED_QUESTIONS,
);

export const loadMostViewedQuestionsSuccess = createAction(
  LOAD_MOST_VIEWED_QUESTIONS_SUCCESS,
  props<{
    questions: Question[];
  }>()
);

export const loadMostViewedQuestionsFailure = createAction(
  LOAD_MOST_VIEWED_QUESTIONS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadMoreMostViewedQuestions = createAction(
  LOAD_MORE_MOST_VIEWED_QUESTIONS,
);

export const loadMoreMostViewedQuestionsSuccess = createAction(
  LOAD_MORE_MOST_VIEWED_QUESTIONS_SUCCESS,
  props<{
    questions: Question[];
  }>()
);

export const loadMoreMostViewedQuestionsFailure = createAction(
  LOAD_MORE_MOST_VIEWED_QUESTIONS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadMostViewedArticles = createAction(
  LOAD_MOST_VIEWED_ARTICLES,
);

export const loadMostViewedArticlesSuccess = createAction(
  LOAD_MOST_VIEWED_ARTICLES_SUCCESS,
  props<{
    articles: Article[];
  }>()
);

export const loadMostViewedArticlesFailure = createAction(
  LOAD_MOST_VIEWED_ARTICLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadMoreMostViewedArticles = createAction(
  LOAD_MORE_MOST_VIEWED_ARTICLES,
);

export const loadMoreMostViewedArticlesSuccess = createAction(
  LOAD_MORE_MOST_VIEWED_ARTICLES_SUCCESS,
  props<{
    articles: Article[];
  }>()
);

export const loadMoreMostViewedArticlesFailure = createAction(
  LOAD_MORE_MOST_VIEWED_ARTICLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadLatestArticles = createAction(
  LOAD_LATEST_ARTICLES,
);

export const loadLatestArticlesSuccess = createAction(
  LOAD_LATEST_ARTICLES_SUCCESS,
  props<{
    articles: Article[];
  }>()
);

export const loadLatestArticlesFailure = createAction(
  LOAD_LATEST_ARTICLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadMoreLatestArticles = createAction(
  LOAD_MORE_LATEST_ARTICLES,
);

export const loadMoreLatestArticlesSuccess = createAction(
  LOAD_MORE_LATEST_ARTICLES_SUCCESS,
  props<{
    articles: Article[];
  }>()
);

export const loadMoreLatestArticlesFailure = createAction(
  LOAD_MORE_LATEST_ARTICLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const search = createAction(
  SEARCH,
  props<{
    query: string;
  }>()
);

export const searchSuccess = createAction(
  SEARCH_SUCCESS,
  props<{
    results: SearchResult[];
  }>()
);

export const searchFailure = createAction(
  SEARCH_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const clearSearch = createAction(
  CLEAR_SEARCH,
);
