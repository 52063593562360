import { createReducer, on } from '@ngrx/store';
import * as networkActions from 'src/app/store/actions/network.actions';

export interface NetworkState {
  isConnected: boolean;
}

export const initialState: NetworkState = {
  isConnected: true,
};

const onNetworkConnection = (state: NetworkState) => ({
    ...state,
    isConnected: true,
  });

const onNetworkDisconnection = (state: NetworkState) => ({
    ...state,
    isConnected: false,
  });

export const reducer = createReducer(
  initialState,
  on(networkActions.networkConnection, onNetworkConnection),
  on(networkActions.networkDisconnection, onNetworkDisconnection),
);
