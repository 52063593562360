import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-question-list-skeleton',
  templateUrl: './questions-list-skeleton.component.html',
})
export class QuestionsListSkeletonComponent implements OnInit {

  itemsNumber: number[] = Array(10).fill(0);
  environment = environment;
  constructor() { }

  ngOnInit() {}


}
