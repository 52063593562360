import { Component, Input, OnInit } from '@angular/core';
import { Chat, Message } from 'src/app/services/chat.service';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'mvp-chat-bubble',
  templateUrl: './chat-bubble.component.html',
})
export class ChatBubbleComponent implements OnInit {

  @Input() message: Message;
  @Input() position = 'left';

  constructor() {}

  ngOnInit() {
  }

  async seeAttachment() {
    const file = this.message.attachment;
    let fileUrl: string;
    // use MVP's API pdf viewer for pdf file on android
    if (Capacitor.getPlatform() === 'android' && file.extension === 'pdf') {
      fileUrl = `${environment.apiHostname}/attachments/${file.uuid}/view`;
    } else {
      fileUrl = `${environment.assetUrl}/attachments/${file.uuid}.${file.extension}`;
    }

    Browser.open({ url: fileUrl });
    return;
  }
}
