import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { of, from, forkJoin, combineLatest } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, tap } from 'rxjs/operators';
import {
  getTokenFailure,
  getTokenSuccess,
  getUserFailure,
  getUserSuccess,
  getDomainSuccess,
  getDomainFailure,
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAILURE,
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_USER_FAILURE,
  LOGIN,
  loginFailure,
  loginSuccess,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER,
  updateUserSuccess,
  updateUserFailure,
  UPDATE_USER_SUCCESS,
  LOGOUT,
  GET_USER,
  logout,
  logoutSuccess,
  logoutFailure,
  LOGOUT_SUCCESS,
  CHECK_EMAIL,
  checkEmailSuccess,
  checkEmailFailure,
  CHECK_EMAIL_SUCCESS,
  CREATE_PASSWORD,
  createPasswordSuccess,
  createPasswordFailure,
  LOGIN_SUBMITTED,
  createPassword,
  login,
  CHECK_EMAIL_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  SET_DOMAIN,
  setDomain,
  CREATE_PASSWORD_FAILURE,
  TOGGLE_NOTIFICATIONS,
  toggleNotificationsSuccess,
  TOGGLE_NOTIFICATIONS_SUCCESS,
  toggleNotificationsFailure,
  TOGGLE_SURVEYS_NOTIFICATIONS,
  toggleSurveysNotificationsSuccess,
  TOGGLE_SURVEYS_NOTIFICATIONS_SUCCESS,
  toggleSurveysNotificationsFailure,
  GET_ROLES,
  getRolesSuccess,
  getRolesFailure,
  getRoles,
  GET_ROLES_SUCCESS,
  GET_DEVICE,
  getDevice,
  getDeviceSuccess,
  getDeviceFailure,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  UPDATE_DEVICE,
  updateDevice,
  updateDeviceSuccess,
  updateDeviceFailure,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_ACCOUNT,
  deleteAccountSuccess,
  deleteAccountFailure,
  DELETE_ACCOUNT_SUCCESS,
} from '../actions/auth.actions';
import { AuthResponse, AuthService, Credentials, Device as AuthDevice, User } from 'src/app/services/auth.service';
import { GetResult, Preferences } from '@capacitor/preferences';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Domain } from 'src/app/services/domain.service';
import { FcmService } from 'src/app/services/fcm.service';
import { HttpErrorResponse } from '@angular/common/http';
import { loadChats } from '../actions/chats.actions';
import { AlertService } from 'src/app/services/alert.service';
import { selectDomain, selectIsOperator, selectPasswordRequired, selectRoles, selectUser } from '../selectors/auth.selectors';
import { SocketService } from 'src/app/services/socket.service';
import { loadSurveys } from '../actions/survey.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGIN),
      concatMap(async ({ email, password }) => [
        { email, password },
        await Device.getId(),
        await Device.getInfo()
      ]),
      exhaustMap(([{ email, password }, deviceId, deviceInfo]: [any, DeviceId, DeviceInfo]) => {
        return this.authService.login({
            email,
            password,
            deviceUuid: deviceId.identifier,
            device: deviceInfo,
            fcmToken: this.fcmService.getFcmToken()
          }).pipe(
          map((response: AuthResponse) => loginSuccess({ ...response })),
          catchError(error => of(loginFailure({ error })))
        );
      })
    );
  });

  checkEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CHECK_EMAIL),
      exhaustMap(({ email }) => {
        return this.authService.checkEmail(email).pipe(
          map(({ domains, needPass, needVerification }: any) => checkEmailSuccess({ domains, needPass, needVerification })),
          catchError(error => of(checkEmailFailure(error))),
        );
      })
    );
  });

  createPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CREATE_PASSWORD),
      exhaustMap(({ email, password, password_confirmation }) => {
        return this.authService.createPassword({ email, password, password_confirmation }).pipe(
          map(() => createPasswordSuccess()),
          catchError(error => of(createPasswordFailure(error))),
        );
      })
    );
  });

  loginSuccessAddTokenToStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGIN_SUCCESS),
      tap(({ token }: AuthResponse) => {
        Preferences.set({
          key: `${environment.application}-jwt`,
          value: token,
        });
      })
    );
  }, {
    dispatch: false
  });

  loginSubmittedDispatchAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGIN_SUBMITTED),
      concatLatestFrom(() => this.store.select(selectPasswordRequired)),
      map(([data, passwordRequired]) => {
        return passwordRequired ? createPassword(data) : login(data);
      })
    );
  });

  loginSuccessLoadInitiatedChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LOGIN_SUCCESS,
        GET_USER_SUCCESS,
      ),
      map(() => loadChats())
    );
  });

  loginSuccessLoadSurveys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GET_DOMAIN_SUCCESS,
        LOGIN_SUCCESS,
      ),
      filter(({ domain }: { domain: Domain }) => domain.survey.is_activated),
      map(({ domain }: { domain: Domain }) => {
        return loadSurveys();
      })
    );
  });

  // getRolesSuccessLoadOperatorChatsIfNeeded$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(
  //       GET_ROLES_SUCCESS,
  //     ),
  //     concatLatestFrom(() => [
  //       this.store.select(selectIsOperator),
  //       this.store.select(selectUser),
  //     ]),
  //     filter(([, isOperator, user]: [any, boolean, User]) => isOperator || user.can_use_chat),
  //     map(() => loadAssignedChats())
  //   );
  // });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UPDATE_USER),
      exhaustMap(({ data }) => {
        return this.authService.updateUser(data).pipe(
          map((user: User) => updateUserSuccess({ user })),
          catchError(error => of(updateUserFailure({ error })))
        );
      })
    );
  });

  alertUserUpdateSuccesful$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UPDATE_USER_SUCCESS),
      tap(() => {
        this.alertService.success('Profil utilisateur mis à jour avec succès !');
      })
    );
  }, {
    dispatch: false
  });

  getToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_TOKEN),
      exhaustMap(() => {
        return from(Preferences.get({ key: `${environment.application}-jwt` })).pipe(
          map(({ value }: GetResult) => {
            if (!value) {
              throw new Error();
            }
            return value;
          }),
          map((token: string) => getTokenSuccess({ token })),
          catchError(error => of(getTokenFailure(error))),
        );
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGOUT),
      exhaustMap(() => {
        return this.authService.logout().pipe(
          map(() => logoutSuccess()),
          catchError(error => of(logoutFailure(error))),
        );
      })
    );
  });

  logoutSuccessRedirectionToLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LOGOUT_SUCCESS,
        DELETE_ACCOUNT_SUCCESS
      ),
      tap(() => {
        this.router.navigate(['login']);
      })
    );
  }, {
    dispatch: false
  });

  logoutUnsubscribeFromFcmTopics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGOUT),
      concatLatestFrom(() => this.store.select(selectDomain)),
      tap(([, domain]: [any, Domain]) => {
        // console.log('DOMAIN, domain', domain)
        this.fcmService.unsubscribe(`news-${domain.uuid}`);
        this.fcmService.unsubscribe(`surveys-${domain.uuid}`);
      })
    );
  }, {
    dispatch: false
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_TOKEN_SUCCESS),
      exhaustMap(() => {
        return this.authService.profile().pipe(
          map((user: User) => getUserSuccess({ user })),
          catchError(error => of(getUserFailure(error))),
        );
      })
    );
  });

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_USER),
      exhaustMap(() => {
        return this.authService.profile().pipe(
          map((user: User) => getUserSuccess({ user })),
          catchError(error => of(getUserFailure(error))),
        );
      })
    );
  });

  updateDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_USER_SUCCESS),
      concatMap(async () => [
        await Device.getId(),
        await Device.getInfo()
      ]),
      exhaustMap(([deviceId, deviceInfo]: [DeviceId, DeviceInfo]) => {
        return this.authService.saveDevice({
            uuid: deviceId.identifier,
            infos: deviceInfo,
            fcmToken: this.fcmService.getFcmToken(),
            isPushEnabled: true
          }).pipe(
            map((device: AuthDevice) => updateDeviceSuccess({ device })),
            catchError(error => of(updateDeviceFailure({ error })))
        );
      })
    );
  });

  loadDomain$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_TOKEN_SUCCESS),
      exhaustMap(() => {
        return this.authService.domain().pipe(
          map((domain: Domain) => getDomainSuccess({ domain })),
          catchError(error => of(getDomainFailure(error))),
        );
      })
    );
  });

  getUserFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_USER_FAILURE),
      map(() => logout())
    );
  });

  getDomainFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_DOMAIN_FAILURE),
      map(() => logout())
    );
  });

  getDomainSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GET_DOMAIN_SUCCESS,
        SET_DOMAIN,
      ),
      tap(({ domain }: { type: string;  domain: Domain }) => {
        Preferences.set({
          key: `${environment.application}-domain`,
          value: domain ? domain.uuid : null,
        });
      })
    );
  }, {
    dispatch: false
  });

  checkEmailSetDomain$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CHECK_EMAIL_SUCCESS),
      map(({ domains }: { domains: Domain[]}) => setDomain({ domain: domains[0] }))
    );
  });

  alertCheckEmailFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CHECK_EMAIL_FAILURE),
      tap(() => {
        this.alertService.danger('L\'adresse e-mail n\'est pas reconnue');
      }),
    );
  }, {
    dispatch: false
  });

  alertCreatePasswordFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CREATE_PASSWORD_FAILURE),
      tap(() => {
        this.alertService.danger('Les deux mots de passe sont différents');
      }),
    );
  }, {
    dispatch: false
  });

  alertCreatePasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CREATE_PASSWORD_SUCCESS),
      tap(() => {
        this.alertService.success('Un email a été envoyé à votre adresse afin de vérifier votre compte.');
      }),
    );
  }, {
    dispatch: false
  });

  loginFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGIN_FAILURE),
      tap(({ error }: { error: HttpErrorResponse | any }) => {
        this.alertService.danger(error.error.message);
      })
    );
  }, {
      dispatch: false
    }
  );

  subscriceToTopics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GET_DOMAIN_SUCCESS,
        LOGIN_SUCCESS,
      ),
      concatLatestFrom(() => [
        this.store.select(selectUser),
        this.store.select(selectDomain),
      ]),
      tap(([, user, domain]: [any, User, Domain]) => {
        if (user?.notifications.news) {
          this.fcmService.subscribe(`news-${domain.uuid}`);
        }
        if (user?.notifications.surveys) {
          this.fcmService.subscribe(`surveys-${domain.uuid}`);
        }
      })
    );
  }, {
    dispatch: false
  });

  toggleNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TOGGLE_NOTIFICATIONS),
      concatLatestFrom(() => this.store.select(selectUser)),
      exhaustMap(([, user]) => {
        return this.authService.toggleNotifications({ news: !user.notifications.news }).pipe(
          map(({ news }: { news: boolean}) => toggleNotificationsSuccess({ news })),
          catchError(error => of(toggleNotificationsFailure(error))),
        );
      })
    );
  });

  toggleSurveysNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TOGGLE_SURVEYS_NOTIFICATIONS),
      concatLatestFrom(() => this.store.select(selectUser)),
      exhaustMap(([, user]) => {
        return this.authService.toggleNotifications({ surveys: !user.notifications.surveys }).pipe(
          map(({ surveys }: { surveys: boolean}) => toggleSurveysNotificationsSuccess({ surveys })),
          catchError(error => of(toggleSurveysNotificationsFailure(error))),
        );
      })
    );
  });

  userSuccessGetRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GET_USER_SUCCESS,
        LOGIN_SUCCESS,
      ),
      map(() => getRoles())
    );
  });

  getRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_ROLES),
      exhaustMap(() => {
        return this.authService.getRoles().pipe(
          map((roles: Array<string>) => getRolesSuccess({ roles })),
          catchError(error => of(getRolesFailure(error))),
        );
      })
    );
  });

  joinOperatorsRoomIfNeeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_ROLES_SUCCESS),
      concatLatestFrom(() => [
        this.store.select(selectIsOperator),
        this.store.select(selectDomain),
      ]),
      tap(([, isOperator, domain]: [any, boolean, Domain]) => {
        if(isOperator) {
          this.socketService.io.emit('subscribe', { room: `${domain.uuid}/operators` });
        }
      })
    );
  }, {
    dispatch: false
  });

  logoutUnsubscribeFromOperatorsRoomIfNeeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGOUT),
      concatLatestFrom(() => [
        this.store.select(selectIsOperator),
        this.store.select(selectDomain),
      ]),
      tap(([, isOperator, domain]: [any, boolean, Domain]) => {
        if(isOperator) {
          this.socketService.io.emit('unsubscribe', { room: `${domain.uuid}/operators` });
        }
      })
    );
  }, {
    dispatch: false
  });

  toggleNotificationsSubscribeToTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TOGGLE_NOTIFICATIONS_SUCCESS),
      concatLatestFrom(() => this.store.select(selectDomain)),
      tap(([{ news }, domain]: [{ news: boolean }, Domain]) => {
        if (news) {
          this.fcmService.subscribe(`news-${domain.uuid}`);
        }
        if (!news) {
          this.fcmService.unsubscribe(`news-${domain.uuid}`);
        }
      })
    );
  }, {
    dispatch: false
  });

  toggleSurveysNotificationsSubscribeToTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TOGGLE_SURVEYS_NOTIFICATIONS_SUCCESS),
      concatLatestFrom(() => this.store.select(selectDomain)),
      tap(([{ surveys }, domain]: [{ surveys: boolean }, Domain]) => {
        if (surveys) {
          this.fcmService.subscribe(`surveys-${domain.uuid}`);
        }
        if (!surveys) {
          this.fcmService.unsubscribe(`surveys-${domain.uuid}`);
        }
      })
    );
  }, {
    dispatch: false
  });

  deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DELETE_ACCOUNT),
      exhaustMap(() => {
        return this.authService.deleteAccount().pipe(
          map(() => deleteAccountSuccess()),
          catchError(error => of(deleteAccountFailure({ error }))),
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private fcmService: FcmService,
    private alertService: AlertService,
    private socketService: SocketService,
  ) { }
}
