import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvp-articles-skeleton',
  templateUrl: './articles-skeleton.component.html',
})
export class ArticlesSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
