import { createSelector } from '@ngrx/store';
import { Survey } from 'src/app/services/surveys.service';
import { AppState } from '..';
import { SurveyState } from '../reducers/survey.reducer';
import isPast from 'date-fns/isPast';

export const selectSurveyState = (state: AppState) => state.survey;

export const selectSurveyList = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.surveys,
)

export const selectNewSurveysCount = createSelector(
  selectSurveyState,
  (state: SurveyState) => state.surveys.reduce((previousValue: number, survey: Survey) => !isPast(Date.parse(survey.end_at.replace(/\s+/g, 'T'))) && survey.current_user_answers.length === 0 ? ++previousValue : previousValue, 0)
)
