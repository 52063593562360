import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private originalUrl: string;
  private latestResource: object;

  constructor() {}

  public setOriginalUrl(url: string): void {
    this.originalUrl = url;
  }

  public getOriginalUrl(): string {
    return this.originalUrl;
  }

  public setLatestResource(type: string, title: string): void {
    this.latestResource = {
      type,
      title,
    }
  }

  public getLatestResource(): object {
    return this.latestResource;
  }

  public clearLatestResource(): void {
    this.latestResource = null;
  }

}
