import { createReducer, on } from '@ngrx/store';
import { AuthResponse, User } from 'src/app/services/auth.service';
import { Domain } from 'src/app/services/domain.service';
import { Notification } from 'src/app/services/interface';
import * as globalActions from 'src/app/store/actions/global.actions';

export interface GlobalState {
  notifications: {
    list: Notification[];
  };
}

export const initialState: GlobalState = {
  notifications: {
    list: [],
  },
};

const onNotify = (state: GlobalState, { notification }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      list: [notification, ...state.notifications.list],
    }
  });

const onClearNotification = (state: GlobalState, { id }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      list: state.notifications.list.filter((notification: Notification) => notification.id !== id),
    }
  });

export const reducer = createReducer(
  initialState,
  on(globalActions.notify, onNotify),
  on(globalActions.clearNotification, onClearNotification),
);
