import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-update-modal',
  templateUrl: './update-modal.component.html',
})
export class UpdateModalComponent implements OnInit {

  constructor(private http: HttpClient, private platform: Platform) { }

  appUrls: any;

  ngOnInit() {
    this.http.get<any>(`${environment.apiUrl}/application/${environment.application}/urls`).subscribe(urls => {
      this.appUrls = urls;
    });
  }

  onUpdateClick() {
    if (this.platform.is('ios')) {
      window.open(this.appUrls.url_appstore, '_blank');
    } else if (this.platform.is('android')) {
      window.open(this.appUrls.url_playstore, '_blank');
    }
  }

  onCancelClick() {
    const modal = document.getElementById('updateModal');
    if (modal) {
      modal.style.display = 'none';
    }
  }
}
