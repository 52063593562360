import { Component, Input, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { Document } from 'src/app/services/document.service';
import { File } from 'src/app/services/interface';

@Component({
  selector: 'mvp-documents-list',
  templateUrl: './documents-list.component.html',
})
export class DocumentsListComponent implements OnInit {

  @Input() documents: Document[];
  assetUrl = environment.assetUrl;

  constructor() { }

  ngOnInit() {}

  showFile(file: File) {
    window.open(file.url, '_blank');
  }
}
