import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Badge } from '@capawesome/capacitor-badge';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  public fcmToken: string;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store,
    private alertService: AlertService
  ) {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', async ({ value }) => {
        let token = value; // Push token for Android

        // Get FCM token instead the APN one returned by Capacitor
        if (Capacitor.getPlatform() === 'ios') {
          const { token: fcm_token } = await FCM.getToken();
          token = fcm_token;
        }
        console.log('FCM token', token);
        this.fcmToken = token;
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        ({ title, body, data }: PushNotificationSchema) => {
          if (data?.article) {
            this.alertService.push(body, {
              icon: 'file-text',
              route: `article/${data.article}`,
              title,
              data,
            });
          }
          if (data?.chat) {
            this.alertService.push(body, {
              icon: 'message-circle',
              route: `chat/${data.chat}`,
              title,
              data,
            });
          }
          if (data?.survey) {
            this.alertService.push(body, {
              icon: 'megaphone',
              route: `sondage/${data.survey}`,
              title,
              data,
            });
          }
        }
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        ({ notification }: ActionPerformed) => {
          const { article, chat, survey, surveygroup } = notification.data;
          if (article) {
            sessionStorage.setItem('deeplink', `article/${article}`);
          } else if (chat) {
            sessionStorage.setItem('deeplink', `chat/${chat}`);
          } else if (survey) {
            sessionStorage.setItem('deeplink', `sondage/${survey}`);
          } else if (surveygroup) {
            sessionStorage.setItem('deeplink', `sondage/${surveygroup}`);
          }

          this.ngZone.run(() => {
            Badge.clear();
            this.handleNavigation(notification.data);
          });
        }
      );
    }
  }

  handleNavigation(data: any) {
    if (data?.article) {
      this.router.navigate([`article/${data.article}`]);
    }
    if (data?.chat) {
      this.router.navigate([`chat/${data.chat}`]);
    }
    if (data?.survey) {
      this.router.navigate([`surveys`]);
    }
  }

  register(): void {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.checkPermissions().then((checkPermission) => {
        PushNotifications.requestPermissions().then((permission) => {
          if (permission.receive === 'granted') {
            PushNotifications.register();
          } else {
            console.error('No Permission for Notifications!');
          }
        });
      });
    }
  }

  async subscribe(topic: string) {
    console.log('Subscribing to topic:', topic);
    if (Capacitor.isNativePlatform()) {
      await FCM.subscribeTo({ topic });
    }
  }

  async unsubscribe(topic: string) {
    console.log('Unsubscribing from topic:', topic);
    if (Capacitor.isNativePlatform()) {
      await FCM.unsubscribeFrom({ topic });
    }
  }

  async getToken() {
    try {
      if (Capacitor.isNativePlatform()) {
        await FCM.getToken();
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
      throw error;
    }
  }

  getFcmToken() {
    return this.fcmToken;
  }
}
