import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Observable, timer } from 'rxjs';
import { Chat, Message } from './services/chat.service';
import { Domain } from './services/domain.service';
import { SocketService } from './services/socket.service';
import { NetworkService } from './services/network.service';
import { UpdateService } from './services/update.service';
import { getToken } from './store/actions/auth.actions';
import {
  receivedChat,
  receivedMessage,
  setConnectedOperators,
} from './store/actions/chats.actions';
import {
  selectDomain,
  selectIsConnected,
} from './store/selectors/auth.selectors';
import { selectChatsNewMessagesCounter } from './store/selectors/chats.selectors';
import { FcmService } from './services/fcm.service';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { fromForeground } from './store/actions/events.actions';
import { filter, take } from 'rxjs/operators';
import { ApiConfig, ConfigService } from './services/config.service';
import { VersionCheckService } from './services/version-check.service';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'mvp-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  config: ApiConfig;
  newMessagesCounter$: Observable<number>;
  domain$: Observable<Domain>;
  isConnected$: Observable<boolean>;
  private handledDeepLink = false;

  @ViewChild('updateModalContainer', { read: ViewContainerRef, static: true })
  updateModalContainer: ViewContainerRef;

  constructor(
    private store: Store,
    private socketService: SocketService,
    private networkService: NetworkService,
    private updateService: UpdateService,
    private fcmService: FcmService,
    private configService: ConfigService,
    private versionCheckService: VersionCheckService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private platform: Platform,
    private router: Router
  ) {
    this.initializeApp();
    this.initializeDeepLinkListener();

    if (Capacitor.isNativePlatform()) {
      this.fcmService.register();
    }
    this.config = configService.apiConfig;
    this.updateService.checkForUpdate();
    this.store.dispatch(getToken());
    this.newMessagesCounter$ = this.store.select(selectChatsNewMessagesCounter);
    this.domain$ = this.store.select(selectDomain);
    this.isConnected$ = this.store.select(selectIsConnected);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
      StatusBar.setBackgroundColor({ color: this.config.app.ui_color_bg });
      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setOverlaysWebView({ overlay: false });

      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          this.store.dispatch(fromForeground());
        }
      });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
        } else if (event instanceof NavigationEnd) {
          this.handleDeferredDeepLink();
        }
      });
    });
  }

  initializeDeepLinkListener() {
    App.addListener('appUrlOpen', (data: any) => {
      const slug = data.url;
      if (slug) {
        this.isConnected$.pipe(take(1)).subscribe((isConnected) => {
          if (isConnected) {
            this.router.navigateByUrl(slug).then(() => {
              this.handledDeepLink = true;
              sessionStorage.setItem('deeplinkHandled', 'true');
            });
          } else {
            this.router.navigateByUrl('/login').then(() => {
              sessionStorage.setItem('deeplink', slug);
            });
          }
        });
      }
    });
  }

  ngOnInit() {
    this.isConnected$
      .pipe(
        filter((val) => val),
        take(1)
      )
      .subscribe(() => {
        this.socketService.io.on(
          'chat:new-message',
          ({ request, message }: { request: Chat; message: Message }) => {
            this.store.dispatch(receivedMessage({ message, chat: request }));
          }
        );
        this.socketService.io.on('auth:connected-list', (data) => {
          this.store.dispatch(
            setConnectedOperators({ list: Object.values<string>(data) })
          );
        });
        this.socketService.io.on('chat:new', (chat: Chat) => {
          this.store.dispatch(receivedChat({ chat }));
        });

        // Handle deferred deep link if any
        const deeplink = sessionStorage.getItem('deeplink');
        const deeplinkHandled = sessionStorage.getItem('deeplinkHandled');
        if (deeplink && !deeplinkHandled) {
          this.router.navigateByUrl(deeplink).then(() => {
            sessionStorage.removeItem('deeplink');
            sessionStorage.setItem('deeplinkHandled', 'true');
          });
        }
      });

    timer(3000).subscribe({
      next: () => {
        this.versionCheckService.checkVersion().subscribe({
          next: (isUpdateAvailable) => {
            if (isUpdateAvailable) {
              this.showUpdateModal();
            }
          },
          error: (error) => {
            console.error('Error checking app version:', error);
          },
        });
      },
    });
  }

  private handleDeferredDeepLink() {
    if (this.handledDeepLink) {
      return;
    }
    const deeplink = sessionStorage.getItem('deeplink');
    if (deeplink) {
      this.router.navigateByUrl(deeplink).then(() => {
        sessionStorage.removeItem('deeplink');
        this.handledDeepLink = true;
        sessionStorage.setItem('deeplinkHandled', 'true');
      });
    }
  }

  private showUpdateModal() {
    this.updateModalContainer.clear();
    this.updateModalContainer.createComponent(UpdateModalComponent);
  }
}
