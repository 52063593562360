import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, from } from 'rxjs';
import { catchError, debounceTime, delay, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Notification } from 'src/app/services/interface';
import { AppState } from '..';
import { clearNotification, NOTIFY } from '../actions/global.actions';

@Injectable()
export class GlobalEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
  ) { }
}
