import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectToken } from '../store/selectors/auth.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
      private store: Store,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectToken).pipe(take(1)).pipe(mergeMap((token: string) => {
      const cloneReq = this.addHeaders(request, token);
      return next.handle(cloneReq);
    })) as any;
  }

  private addHeaders(request: HttpRequest<any>, token: string) {
    const clone: HttpRequest<any> = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        IsMobile: 'true',
      }
    });
    return clone;
  }
}
