import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsConnectedGuard } from './guards/is-connected.guard';
import { IsGuestGuard } from './guards/is-guest.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'chat/:uuid',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
    canActivate: [
      IsConnectedGuard,
    ]
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [
      IsConnectedGuard,
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [
      IsGuestGuard,
    ]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/password/password.module').then(m => m.PasswordPageModule),
    canActivate: [
      IsConnectedGuard,
    ]
  },
  // {
  //   path: 'article/:uuid',
  //   loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule),
  //   canActivate: [
  //     IsConnectedGuard,
  //   ]
  // },
  // {
  //   path: 'question/:uuid',
  //   loadChildren: () => import('./pages/question/question.module').then(m => m.QuestionPageModule),
  //   canActivate: [
  //     IsConnectedGuard,
  //   ]
  // },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
    canActivate: [
      IsGuestGuard
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
