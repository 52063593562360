import { Injectable } from '@angular/core';
import { AlertController, AlertButton } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface UpdateMessage {
  title: string;
  body: string;
}

export interface AppUpdate {
  version: string;
  under_maintenance: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  messages = {
    maintenance: {
      title: 'Maintenance',
      body: 'Nos serveurs sont actuellement en maintenance, veuillez nous excuser de ce désagrément',
    },
    minor: {
      title: 'Information',
      // eslint-disable-next-line max-len
      body: 'Une nouvelle version de l\'application est actuellement disponible, veuillez cliquer sur le lien ci-dessous pour la télécharger',
    },
    major: {
      title: 'Mise à jour',
      body: 'Une mise à jour de l\'application est nécessaire afin de poursuivre  son utilisation',
    }
  };

  constructor(
    private alertController: AlertController,
    private httpClient: HttpClient,
  ) { }

  async checkForUpdate() {
    this.httpClient
      .get(`${environment.apiUrl}/application/${environment.application}`)
      .subscribe(async ({ under_maintenance, version }: AppUpdate) => {
        if (under_maintenance) {
          this.presentAlert(this.messages.maintenance, false, true);
        } else {
          if(Capacitor.isNativePlatform()) {
            const appInfo: AppInfo = await App.getInfo();
            const serverVersion = version.split('.');
            const appVersion = appInfo.version.split('.');


            if (
              parseInt(serverVersion[2], 10) > parseInt(appVersion[2], 10) &&
              parseInt(serverVersion[1], 10) === parseInt(appVersion[1], 10) &&
              parseInt(serverVersion[0], 10) === parseInt(appVersion[0], 10)
            ) {
              this.presentAlert(this.messages.minor, true);
            }

            if (
              parseInt(serverVersion[0], 10) > parseInt(appVersion[0], 10) ||
              parseInt(serverVersion[1], 10) > parseInt(appVersion[1], 10)
            ) {
              this.presentAlert(this.messages.major, false);
            }
          }
        }
      });
  }

  async openAppStoreEntry() {
    const url = Capacitor.getPlatform() === 'android'
      ? 'https://play.google.com/store/apps/details?id=com.ingeneris.' + environment.application
      : 'https://apps.apple.com/fr/app/' + environment.application + '/id1532383397';
    await Browser.open({ url });
    Browser.addListener('browserFinished', () => {
      this.checkForUpdate();
    });
  }

  async presentAlert(message: UpdateMessage, allowClose: boolean = false, inMaintenance: boolean = false) {
    const buttons: AlertButton[] = [];

    if (!inMaintenance) {
      buttons.push({
        text: 'Télécharger',
        handler: () => {
          this.openAppStoreEntry();
        }
      });
    }

    if (allowClose) {
      buttons.push({
        text: 'Fermer',
        role: 'cancel'
      });
    }

    const alert = await this.alertController.create({
      header: message.title,
      message: message.body,
      buttons,
      backdropDismiss: allowClose
    });

    await alert.present();
  }
}
