import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/services/article.service';
import { SearchResult } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-articles-list',
  templateUrl: './articles-list.component.html',
})
export class ArticlesListComponent implements OnInit {
  @ViewChild('list', { static: true }) list: ElementRef<HTMLUListElement>;

  @Input() articles: Array<Article | SearchResult>;
  @Input() toFullList: boolean = false;
  @Input() listSorting?: string;
  @Input() isVariantColor: boolean = false;

  assetUrl: string = environment.assetUrl;
  activeCardIndex: number = 0;
  environment = environment;

  constructor(private router: Router) {}

  ngOnInit() {
    this.updateActiveChip();
  }

  getThumbnailUrl(article: Article | SearchResult) {
    return `${this.assetUrl}/thumbnails/${article.thumbnail}`;
  }

  goToArticle(article: Article | SearchResult) {
    let queryParams: any = {};
    if (this.listSorting) {
      const sortDirection = this.listSorting.startsWith('-') ? 'desc' : 'asc';
      const sortColumn = this.listSorting.startsWith('-') ? this.listSorting.substring(1) : this.listSorting;
      queryParams.sort = sortColumn;
      queryParams.direction = sortDirection;
    }
    this.router.navigate([`article/${article.uuid}`], { queryParams });
  }

  goToList() {
    let queryParams: any = {};
    if (this.listSorting) queryParams.sort = this.listSorting;
    this.router.navigate(['/news/list'], { queryParams });
  }

  scrollLeft() {
    this.list.nativeElement.scrollBy({ left: -300, behavior: 'smooth' });
  }

  scrollRight() {
    this.list.nativeElement.scrollBy({ left: 300, behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateActiveChip();
  }

  onScroll() {
    this.updateActiveChip();
  }

  updateActiveChip() {
    const listElement = this.list.nativeElement;
    const scrollLeft = listElement.scrollLeft;
    const viewportWidth = listElement.offsetWidth;

    let maxVisibleWidth = 0;
    let newActiveCardIndex = 0;

    const children = listElement.children;

    for (let i = 0; i < children.length; i++) {
      const card = children[i] as HTMLElement;
      const cardStart = card.offsetLeft - scrollLeft;
      const cardEnd = cardStart + card.offsetWidth;

      const visibleWidth = Math.min(cardEnd, viewportWidth) - Math.max(cardStart, 0);

      if (visibleWidth > maxVisibleWidth) {
        maxVisibleWidth = visibleWidth;
        newActiveCardIndex = i;
      }
    }

    this.activeCardIndex = newActiveCardIndex;
  }

  activeChipColor() {
    if (environment.application === 'tract') {
      return this.isVariantColor ? ' bg-gray-400 ' : ' bg-[#FFA500] ';
    }

    return ' bg-[#007bff] ';
  }
}
