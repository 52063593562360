import { createSelector, select } from '@ngrx/store';
import { AppState } from '..';
import { AuthState } from '../reducers/auth.reducer';

export const selectAuthState = (state: AppState) => state.auth;

export const selectToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectDomain = createSelector(
  selectAuthState,
  (state: AuthState) => state.domain
);

export const selectIsConnected = createSelector(
  selectAuthState,
  (state: AuthState) => state.isConnected
);

export const selectIsGuest = createSelector(
  selectAuthState,
  (state: AuthState) => !state.isConnected
);

export const selectIsLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoading
);

export const selectEmail = createSelector(
  selectAuthState,
  (state: AuthState) => state.email
);

export const selectPasswordRequired = createSelector(
  selectAuthState,
  (state: AuthState) => state.passwordRequired
);

export const selectVerificationRequired = createSelector(
  selectAuthState,
  (state: AuthState) => state.verificationRequired
);

export const selectDomains = createSelector(
  selectAuthState,
  (state: AuthState) => state.domains
);

export const selectRoles = createSelector(
  selectAuthState,
  (state: AuthState) => state.roles
);

export const selectIsOperator = createSelector(
  selectAuthState,
  (state: AuthState) => state.roles.includes('operator') || state.user?.can_use_chat
);
