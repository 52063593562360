import { environment } from 'src/environments/environment';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StatusBar } from '@capacitor/status-bar';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { Domain } from 'src/app/services/domain.service';
import { AuthService, User } from 'src/app/services/auth.service';
import { selectDomain, selectUser } from 'src/app/store/selectors/auth.selectors';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { logout } from 'src/app/store/actions/auth.actions';
import { trigger, style, transition, animate } from '@angular/animations';
import { ApiConfig, ConfigService } from 'src/app/services/config.service';
import { Capacitor } from '@capacitor/core';
// import { AppLauncher } from '@capacitor/app-launcher';
import { NavigationService } from 'src/app/services/navigation.service';
@Component({
  selector: 'mvp-header',
  templateUrl: './header.component.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  config: ApiConfig;
  user: User;
  domain: Domain;
  domain$: Observable<Domain>;
  assetUrl: string = environment.assetUrl;
  currentRoute: string;
  isMenuOpen = false;

  constructor(
    private store: Store,
    private router: Router,
    private menu: MenuController,
    private alertController: AlertController,
    private el: ElementRef,
    private renderer: Renderer2,
    private configService: ConfigService,
    private platform: Platform,
    private navigationService: NavigationService
  ) {
    this.config = configService.apiConfig;
    this.store.select(selectUser).pipe(
      filter((user: User) => user !== null)
    ).subscribe((user: User) => {
      this.user = user;
    });
    this.domain$ = this.store.select(selectDomain);
    this.store.select(selectDomain).pipe(
      filter((domain: Domain) => domain !== null)
    ).subscribe((domain: Domain) => {
      this.domain = domain;
      if (Capacitor.isNativePlatform()) {
        StatusBar.setBackgroundColor({ color: this.domain.color });
      }
    });
    // set currentRoute
    this.currentRoute = this.router.url;
    // events on Router Update
    router.events.subscribe((link) => {
      // get current url path
      if (link instanceof NavigationEnd) {
        this.currentRoute = link.url;
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (document.getElementById('toggleMenu')) {
      document.getElementById('toggleMenu').addEventListener('click', () =>  {
        const menu = document.getElementById('sideMenu');
        const isOpen = menu.classList.contains('menu-open');
        if (isOpen) {
            menu.classList.remove('menu-open');
            menu.classList.add('menu-close');
        } else {
            menu.classList.remove('menu-close');
            menu.classList.add('menu-open');
        }
      });
    }
    // const menuLinks = this.el.nativeElement.querySelectorAll('li');
    // console.log(menuLinks);
    // menuLinks.forEach((item: HTMLElement) => {
    //   this.renderer.listen(item, 'click', () => this.closeMenu());
    // });
  }

  getAvatarUrl() {
    return `${this.assetUrl}/avatars/${this.user.avatar}.png`;
  }

  domainSwitch(): void {
    console.log(this.user.domains);
  }

  getLogoUrl() {
    return `${this.assetUrl}/logos/`;
  }

  openMenu() {
    this.isMenuOpen = true;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openExternalWebsite(url: string) {
    window.open(url, '_blank');
  }

  isIOS() {
    return this.platform.is('ios');
  }

  isAndroid() {
    return this.platform.is('android');
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Déconnexion',
      message: 'Souhaitez-vous vous déconnecter ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Déconnexion',
          handler: () => {
            this.store.dispatch(logout());
          }
        }
      ]
    });
    await alert.present();
  }

  handleContactClick() {
    this.navigationService.clearLatestResource();
    this.closeMenu();
  }

}
