import { createAction, props } from '@ngrx/store';

export const SOCKET_CONNECTION = '[Socket] Socket Connection';
export const SOCKET_DISCONNECTION = '[Socket] Socket Disconnection';

export const socketConnection = createAction(
  SOCKET_CONNECTION,
);

export const socketDisconnection = createAction(
  SOCKET_DISCONNECTION,
);
