import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AuthResponse, User } from 'src/app/services/auth.service';
import { Domain } from 'src/app/services/domain.service';

export const LOGIN_SUBMITTED = '[Auth] Login Submitted';
export const SET_EMAIL = '[Auth] Set Email';
export const SET_DOMAIN = '[Auth] Set Domain';
export const CLEAR_DOMAINS = '[Auth] Clear Domains';

export const CHECK_EMAIL = '[Auth] Check Email';
export const CHECK_EMAIL_SUCCESS = '[Auth] Check Email Success';
export const CHECK_EMAIL_FAILURE = '[Auth] Check Email Failure';

export const CREATE_PASSWORD = '[Auth] Create Password';
export const CREATE_PASSWORD_SUCCESS = '[Auth] Create Password Success';
export const CREATE_PASSWORD_FAILURE = '[Auth] Create Password Failure';

export const UPDATE_USER = '[Auth] Update User';
export const UPDATE_USER_SUCCESS = '[Auth] Update User Success';
export const UPDATE_USER_FAILURE = '[Auth] Update User Failure';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';

export const GET_TOKEN = '[Auth] Get Token';
export const GET_TOKEN_SUCCESS = '[Auth] Get Token Success';
export const GET_TOKEN_FAILURE = '[Auth] Get Token Failure';

export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAILURE = '[Auth] Logout Failure';

export const GET_USER = '[Auth] Get User';
export const GET_USER_SUCCESS = '[Auth] Get User Success';
export const GET_USER_FAILURE = '[Auth] Get User Failure';
export const CLEAR_USER = '[Auth] Clear User';

export const GET_ROLES = '[Auth] Get Roles';
export const GET_ROLES_SUCCESS = '[Auth] Get Roles Success';
export const GET_ROLES_FAILURE = '[Auth] Get Roles Failure';

export const GET_DOMAIN = '[Auth] Get Domain';
export const GET_DOMAIN_SUCCESS = '[Auth] Get Domain Success';
export const GET_DOMAIN_FAILURE = '[Auth] Get Domain Failure';

export const GET_DEVICE = '[Auth] Get Device';
export const GET_DEVICE_SUCCESS = '[Auth] Get Device Success';
export const GET_DEVICE_FAILURE = '[Auth] Get Device Failure';

export const UPDATE_DEVICE = '[Auth] Update Device';
export const UPDATE_DEVICE_SUCCESS = '[Auth] Update Device Success';
export const UPDATE_DEVICE_FAILURE = '[Auth] Update Device Failure';

export const TOGGLE_NOTIFICATIONS = '[Auth] Toggle Notifications';
export const TOGGLE_NOTIFICATIONS_SUCCESS = '[Auth] Toggle Notifications Success';
export const TOGGLE_NOTIFICATIONS_FAILURE = '[Auth] Toggle Notifications Failure';

export const TOGGLE_SURVEYS_NOTIFICATIONS = '[Auth] Toggle Surveys Notifications';
export const TOGGLE_SURVEYS_NOTIFICATIONS_SUCCESS = '[Auth] Toggle Surveys Notifications Success';
export const TOGGLE_SURVEYS_NOTIFICATIONS_FAILURE = '[Auth] Toggle Surveys Notifications Failure';

export const DELETE_ACCOUNT = '[Auth] Delete Account';
export const DELETE_ACCOUNT_SUCCESS = '[Auth] Delete Account Success';
export const DELETE_ACCOUNT_FAILURE = '[Auth] Delete Account Failure';

export const setEmail = createAction(
  SET_EMAIL,
  props<{
    email: string;
  }>()
);

export const setDomain = createAction(
  SET_DOMAIN,
  props<{
    domain: Domain;
  }>()
);

export const clearDomains = createAction(
  CLEAR_DOMAINS,
);

export const loginSubmitted = createAction(
  LOGIN_SUBMITTED,
  props<{
    email: string;
    password: string;
    password_confirmation?: string;
  }>()
);

export const updateUser = createAction(
  UPDATE_USER,
  props<{
    data: any;
  }>()
);

export const updateUserSuccess = createAction(
  UPDATE_USER_SUCCESS,
  props<{
    user: User;
  }>()
);

export const updateUserFailure = createAction(
  UPDATE_USER_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const checkEmail = createAction(
  CHECK_EMAIL,
  props<{
    email: string;
  }>()
);

export const checkEmailSuccess = createAction(
  CHECK_EMAIL_SUCCESS,
  props<{
    domains: Domain[];
    needPass: boolean;
    needVerification: boolean;
  }>()
);

export const checkEmailFailure = createAction(
  CHECK_EMAIL_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const createPassword = createAction(
  CREATE_PASSWORD,
  props<{
    email: string;
    password: string;
    password_confirmation: string;
  }>()
);

export const createPasswordSuccess = createAction(
  CREATE_PASSWORD_SUCCESS
);

export const createPasswordFailure = createAction(
  CREATE_PASSWORD_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const login = createAction(
  LOGIN,
  props<{
    email: string;
    password: string;
  }>()
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{
    token: string;
    expires_at: Date;
    token_type: string;
    user: User;
  }>()
);

export const loginFailure = createAction(
  LOGIN_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const getToken = createAction(
  GET_TOKEN
);

export const getTokenSuccess = createAction(
  GET_TOKEN_SUCCESS,
  props<{ token: string}>()
);

export const getTokenFailure = createAction(
  GET_TOKEN_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const logout = createAction(
  LOGOUT
);

export const logoutSuccess = createAction(
  LOGOUT_SUCCESS,
);

export const logoutFailure = createAction(
  LOGOUT_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const getUser = createAction(
  GET_USER
);

export const getUserSuccess = createAction(
  GET_USER_SUCCESS,
  props<{ user: User }>()
);

export const getUserFailure = createAction(
  GET_USER_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const getDomain = createAction(
  GET_DOMAIN
);

export const getDomainSuccess = createAction(
  GET_DOMAIN_SUCCESS,
  props<{ domain: Domain }>()
);

export const getDomainFailure = createAction(
  GET_DOMAIN_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const toggleNotifications = createAction(
  TOGGLE_NOTIFICATIONS
);

export const toggleNotificationsSuccess = createAction(
  TOGGLE_NOTIFICATIONS_SUCCESS,
  props<{ news: boolean }>()
);

export const toggleNotificationsFailure = createAction(
  TOGGLE_NOTIFICATIONS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const toggleSurveysNotifications = createAction(
  TOGGLE_SURVEYS_NOTIFICATIONS
);

export const toggleSurveysNotificationsSuccess = createAction(
  TOGGLE_SURVEYS_NOTIFICATIONS_SUCCESS,
  props<{ surveys: boolean }>()
);

export const toggleSurveysNotificationsFailure = createAction(
  TOGGLE_SURVEYS_NOTIFICATIONS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const getRoles = createAction(
  GET_ROLES,
);

export const getRolesSuccess = createAction(
  GET_ROLES_SUCCESS,
  props<{
    roles: Array<string>;
  }>()
);

export const getRolesFailure = createAction(
  GET_ROLES_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const getDevice = createAction(
  GET_DEVICE
);

export const getDeviceSuccess = createAction(
  GET_DEVICE_SUCCESS,
  props<{ device: any}>()
);

export const getDeviceFailure = createAction(
  GET_DEVICE_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const updateDevice = createAction(
  UPDATE_DEVICE
);

export const updateDeviceSuccess = createAction(
  UPDATE_DEVICE_SUCCESS,
  props<{ device: any}>()
);

export const updateDeviceFailure = createAction(
  UPDATE_DEVICE_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const deleteAccount = createAction(
  DELETE_ACCOUNT,
);

export const deleteAccountSuccess = createAction(
  DELETE_ACCOUNT_SUCCESS,
);

export const deleteAccountFailure = createAction(
  DELETE_ACCOUNT_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);
