import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Chat } from 'src/app/services/chat.service';
import { AppState } from 'src/app/store';
import { selectCurrentChat, selectIsLoading } from 'src/app/store/selectors/chats.selectors';
import { selectIsDisconnectedFromSocket } from 'src/app/store/selectors/socket.selectors';

export interface ChatInputs {
  message: string;
  attachment: File;
}
@Component({
  selector: 'mvp-chat-inputs',
  templateUrl: './chat-inputs.component.html',
})
export class ChatInputsComponent implements OnInit {

  @Output() onSend = new EventEmitter<ChatInputs>();
  attachment: File;
  message: string;
  isConnectedToSocket$: Observable<boolean>;
  isDisconnectedFromSocket$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  currentChat$: Observable<Chat>;

  constructor(
    private store: Store,
  ) {
    this.isDisconnectedFromSocket$ = this.store.select(selectIsDisconnectedFromSocket);
    this.currentChat$ = this.store.select(selectCurrentChat);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  ngOnInit() {
    this.currentChat$.subscribe(() => {
      this.reset();
    });
  }

  send() {
    this.onSend.emit({
      message: this.message,
      attachment: this.attachment,
    });
  }

  typing(isTyping: boolean) {
    //
  }

  handleFile(event: any) {
    const file: File = event.target.files[0];
    this.attachment = file;
  }

  reset() {
    this.message = null;
    this.attachment = null;
  }

}
