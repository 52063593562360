import { createAction, props } from '@ngrx/store';

export const NETWORK_CONNECTION = '[Network] Network Connection';
export const NETWORK_DISCONNECTION = '[Network] Network Disconnection';

export const networkConnection = createAction(
  NETWORK_CONNECTION,
);

export const networkDisconnection = createAction(
  NETWORK_DISCONNECTION,
);
