import { NgModule } from '@angular/core';
import { LucideAngularModule } from 'lucide-angular';

import {
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Book,
  Check,
  Download,
  Edit,
  Eye,
  EyeOff,
  Files,
  FileText,
  Frown,
  Headphones,
  Heart,
  HelpCircle,
  Image,
  Lock,
  LogOut,
  Megaphone,
  Mail,
  Meh,
  MessageCircle,
  Monitor,
  Pencil,
  Paperclip,
  Phone,
  Plus,
  Search,
  Settings,
  Smile,
  Star,
  StarHalf,
  Trash,
  User,
  Unlock,
  Wifi,
  Upload,
  X,
  Globe,
  ChevronRight,
  ChevronLeft,
  Menu,
  CornerDownRight
} from 'lucide-angular';

const icons = {
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Book,
  Check,
  Download,
  Edit,
  Eye,
  EyeOff,
  Files,
  FileText,
  Frown,
  Headphones,
  Heart,
  HelpCircle,
  Image,
  Lock,
  LogOut,
  Mail,
  Megaphone,
  Meh,
  MessageCircle,
  Monitor,
  Pencil,
  Paperclip,
  Phone,
  Plus,
  Search,
  Settings,
  Smile,
  Star,
  StarHalf,
  Trash,
  User,
  Unlock,
  Wifi,
  Upload,
  X,
  Globe,
  ChevronRight,
  ChevronLeft,
  Menu,
  CornerDownRight
};

@NgModule({
  imports: [
    LucideAngularModule.pick(icons)
  ],
  exports: [
    LucideAngularModule
  ]
})

export class IconsModule {}
