import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number): unknown {
    var base = 1024, prefixes = ['k','M','G','T'];
    var exp = Math.log(value) / Math.log(base) | 0;
    return (value / Math.pow(base, exp)).toFixed(1) + ' ' +
    ((exp > 0) ? prefixes[exp - 1 ] + 'o' : 'Bytes');
  }

}
