import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { logout } from '../store/actions/auth.actions';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const toaster = this.injector.get(ToastController);
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.store.dispatch(logout());
          return throwError(error);
        } else if (error.status === 500) {
          toaster.create({
            message: 'Une erreur avec le serveur a eu lieu',
            duration: 2000,
            position: 'top',
            color: 'danger'
          }).then(toast => {
            toast.present();
          });
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
