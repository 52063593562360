import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Domain } from './domain.service';
import { File } from './interface';

export interface Question {
  uuid: string;
  title: string;
  response: string;
  keywords: Array<string>;
  position: number;
  is_global: boolean;
  created_at: string;
  updated_at: string;
  is_enabled: boolean;
  thumbnail: string;
  consultations_count: number;
  category: QuestionCategory;
  type: QuestionType;
  documents: Array<File>;
  prevUuid?: string;
  nextUuid?: string;
}

export interface QuestionType {
  uuid: string;
  name: string;
}

export interface QuestionCategory {
  uuid: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private httpClient: HttpClient
  ) { }

  list({ domain }: {
    domain?: Domain;
  }) {
    const url = `${environment.apiUrl}/faq?`;
    return this.httpClient.get<Question[]>(url);
  }

  one(uuid: string): Observable<Question> {
    return this.httpClient.get<Question>(`${environment.apiUrl}/faq/${uuid}`);
  }

  consult(uuid: string) {
    return this.httpClient.post(`${environment.apiUrl}/faq/${uuid}/consult`, {}).toPromise();
  }

  rate(uuid: string, rating: number) {
    return this.httpClient.post(`${environment.apiUrl}/faq/${uuid}/rate`, { rating }).toPromise();
  }
}
