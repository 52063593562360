import * as documentsActions from 'src/app/store/actions/document.actions';
import { Document } from 'src/app/services/document.service';
import { createReducer } from '@ngrx/store';

export interface DocumentState {
    documents: Document[];
}

export const initialState: DocumentState = {
    documents: []
};

export const reducer = createReducer(
    initialState,
);