import { Component, Input, OnInit } from '@angular/core';
import { File } from 'src/app/services/interface';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'mvp-files-list',
  templateUrl: './files-list.component.html',
})
export class FilesListComponent implements OnInit {

  @Input() files: File[];
  assetUrl = environment.assetUrl;

  constructor() { }

  ngOnInit() {}

  openFile(file: File): void {
    let fileUrl: string;
    // use MVP's API pdf viewer for pdf file on android
    if (Capacitor.getPlatform() === 'android' && file.extension === 'pdf') {
      fileUrl = `${environment.apiHostname}/files/${file.uuid}/view`;
    } else {
      fileUrl = `${environment.assetUrl}/files/${file.uuid}.${file.extension}`;
    }

    Browser.open({ url: fileUrl });
    return;
  }
}
