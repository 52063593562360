import { createReducer, on } from '@ngrx/store';
import { Article } from 'src/app/services/article.service';
import { AuthResponse, User } from 'src/app/services/auth.service';
import { Domain } from 'src/app/services/domain.service';
import { Question } from 'src/app/services/question.service';
import { SearchResult } from 'src/app/services/search.service';
import * as filesActions from 'src/app/store/actions/files.actions';


export interface FilesQueries {
  page?: number;
  sort: string;
  mostViewed?: boolean;

}
export interface StoredQuestions {
  list: Question[];
  queries: FilesQueries;
  noMoreData: boolean;
  isLoading: boolean;
  error: any;
}

export interface StoredArticles {
  list: Article[];
  queries: FilesQueries;
  noMoreData: boolean;
  isLoading: boolean;
  error: any;
}
export interface FilesState {
  mostViewedQuestions: StoredQuestions;
  pinnedArticles: StoredArticles;
  mostViewedArticles: StoredArticles;
  latestArticles: StoredArticles;
  search: {
    query: string;
    list: SearchResult[];
    isLoading: boolean;
    error: any;
  };
}

export const initialState: FilesState = {
  mostViewedQuestions: {
    list: [],
    queries: {
      page: 1,
      sort: '-consultations_count',
      mostViewed: true,
    },
    noMoreData: false,
    isLoading: false,
    error: null,
  },
  pinnedArticles: {
    list: [],
    queries: {
      page: 1,
      sort: 'pinned_position',
    },
    noMoreData: false,
    isLoading: false,
    error: null,
  },
  mostViewedArticles: {
    list: [],
    queries: {
      page: 1,
      sort: '-consultations_count',
    },
    noMoreData: false,
    isLoading: false,
    error: null,
  },
  latestArticles: {
    list: [],
    queries: {
      page: 1,
      sort: '-publication_date'
    },
    noMoreData: false,
    isLoading: false,
    error: null,
  },
  search: {
    query: '',
    list: null,
    isLoading: false,
    error: null,
  }
};

const onLoadPinnedArticles = (state: FilesState): FilesState => {
  return {
    ...state,
    pinnedArticles: {
      ...state.pinnedArticles,
      isLoading: true,
    },
  };
};

const onLoadPinnedArticlesSuccess = (state: FilesState, { articles }): FilesState => {
  return {
    ...state,
    pinnedArticles: {
      ...state.pinnedArticles,
      isLoading: false,
      list: articles,
      noMoreData: (articles.length < 10),
      queries: {
        ...state.latestArticles.queries,
        page: 1,
      }
    },
  };
};

const onLoadPinnedArticlesFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    pinnedArticles: {
      ...state.pinnedArticles,
      error,
      isLoading: false,
      queries: {
        ...state.latestArticles.queries,
        page: 1,
      }
    },
  };
};

/***************** MOST VIEWED QUESTIONS *******************/
const onLoadMostViewedQuestions = (state: FilesState): FilesState => {
  return {
    ...state,
    mostViewedQuestions: {
      ...state.mostViewedQuestions,
      isLoading: true,
    },
  };
};

const onLoadMostViewedQuestionsSuccess = (state: FilesState, { questions }): FilesState => {
  return {
    ...state,
    mostViewedQuestions: {
      ...state.mostViewedQuestions,
      isLoading: false,
      noMoreData: (questions.length < 10),
      list: questions,
      queries: {
        ...state.mostViewedQuestions.queries,
        page: 1,
      }
    },
  };
};

const onLoadMostViewedQuestionsFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    mostViewedQuestions: {
      ...state.mostViewedQuestions,
      error,
      isLoading: false,
      queries: {
        ...state.mostViewedQuestions.queries,
        page: 1,
      }
    },
  };
};

const onLoadMoreMostViewedQuestionsSuccess = (state: FilesState, { questions }): FilesState => {
  return {
    ...state,
    mostViewedQuestions: {
      ...state.mostViewedQuestions,
      isLoading: false,
      list: [
        ...state.mostViewedQuestions.list,
        ...questions
      ],
      noMoreData: (questions.length < 10),
      queries: {
        ...state.mostViewedQuestions.queries,
        page: (state.mostViewedQuestions.queries.page + 1),
      }
    },
  };
};

const onLoadMoreMostViewedQuestionsFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    mostViewedQuestions: {
      ...state.mostViewedQuestions,
      error,
      isLoading: false,
    },
  };
};

/***************** MOST VIEWED ARTICLES *******************/
const onLoadMostViewedArticles = (state: FilesState): FilesState => {
  return {
    ...state,
    mostViewedArticles: {
      ...state.mostViewedArticles,
      isLoading: true,
    },
  };
};

const onLoadMostViewedArticlesSuccess = (state: FilesState, { articles }): FilesState => {
  return {
    ...state,
    mostViewedArticles: {
      ...state.mostViewedArticles,
      isLoading: false,
      list: articles,
      noMoreData: (articles.length < 10),
      queries: {
        ...state.mostViewedArticles.queries,
        page: 1,
      }
    },
  };
};

const onLoadMostViewedArticlesFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    mostViewedArticles: {
      ...state.mostViewedArticles,
      error,
      isLoading: false,
      queries: {
        ...state.mostViewedArticles.queries,
        page: 1,
      }
    },
  };
};

const onLoadMoreMostViewedArticlesSuccess = (state: FilesState, { articles }): FilesState => {
  return {
    ...state,
    mostViewedArticles: {
      ...state.mostViewedArticles,
      isLoading: false,
      list: [
        ...state.mostViewedArticles.list,
        ...articles
      ],
      noMoreData: (articles.length < 10),
      queries: {
        ...state.mostViewedArticles.queries,
        page: (state.mostViewedArticles.queries.page + 1),
      }
    },
  };
};

const onLoadMoreMostViewedArticlesFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    mostViewedArticles: {
      ...state.mostViewedArticles,
      error,
      isLoading: false,
    },
  };
};

/***************** LATEST ARTICLES *******************/
const onLoadLatestArticles = (state: FilesState): FilesState => {
  return {
    ...state,
    latestArticles: {
      ...state.latestArticles,
      isLoading: true,
    },
  };
};

const onLoadLatestArticlesSuccess = (state: FilesState, { articles }): FilesState => {
  return {
    ...state,
    latestArticles: {
      ...state.latestArticles,
      isLoading: false,
      list: articles,
      noMoreData: (articles.length < 10),
      queries: {
        ...state.latestArticles.queries,
        page: 1,
      }
    },
  };
};

const onLoadLatestArticlesFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    latestArticles: {
      ...state.latestArticles,
      error,
      isLoading: false,
      queries: {
        ...state.latestArticles.queries,
        page: 1,
      }
    },
  };
};

const onLoadMoreLatestArticlesSuccess = (state: FilesState, { articles }): FilesState => {
  return {
    ...state,
    latestArticles: {
      ...state.latestArticles,
      isLoading: false,
      list: [
        ...state.latestArticles.list,
        ...articles
      ],
      noMoreData: (articles.length < 10),
      queries: {
        ...state.latestArticles.queries,
        page: (state.latestArticles.queries.page + 1),
      }
    },
  };
};

const onLoadMoreLatestArticlesFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    latestArticles: {
      ...state.latestArticles,
      error,
      isLoading: false,
    },
  };
};

const onSearch = (state: FilesState, { query }): FilesState => {
  return {
    ...state,
    search: {
      ...state.search,
      query,
      isLoading: true,
    }
  };
};

const onSearchSuccess = (state: FilesState, { results }): FilesState => {
  return {
    ...state,
    search: {
      ...state.search,
      isLoading: false,
      list: results,
    }
  };
};

const onSearchFailure = (state: FilesState, { error }): FilesState => {
  return {
    ...state,
    search: {
      ...state.search,
      isLoading: false,
      error,
    }
  };
};

const onClearSearch = (state: FilesState): FilesState => {
  return {
    ...state,
    search: {
      ...state.search,
      list: null,
      isLoading: false,
      error: null,
      query: '',
    }
  };
};

export const reducer = createReducer(
  initialState,
  on(filesActions.loadMostViewedQuestions, onLoadMostViewedQuestions),
  on(filesActions.loadMostViewedQuestionsSuccess, onLoadMostViewedQuestionsSuccess),
  on(filesActions.loadMostViewedQuestionsFailure, onLoadMostViewedQuestionsFailure),
  on(filesActions.loadMoreMostViewedQuestionsSuccess, onLoadMoreMostViewedQuestionsSuccess),
  on(filesActions.loadMoreMostViewedQuestionsFailure, onLoadMoreMostViewedQuestionsFailure),
  on(filesActions.loadMostViewedArticles, onLoadMostViewedArticles),
  on(filesActions.loadMostViewedArticlesSuccess, onLoadMostViewedArticlesSuccess),
  on(filesActions.loadMostViewedArticlesFailure, onLoadMostViewedArticlesFailure),
  on(filesActions.loadMoreMostViewedArticlesSuccess, onLoadMoreMostViewedArticlesSuccess),
  on(filesActions.loadMoreMostViewedArticlesFailure, onLoadMoreMostViewedArticlesFailure),
  on(filesActions.loadLatestArticles, onLoadLatestArticles),
  on(filesActions.loadLatestArticlesSuccess, onLoadLatestArticlesSuccess),
  on(filesActions.loadLatestArticlesFailure, onLoadLatestArticlesFailure),
  on(filesActions.loadMoreLatestArticlesSuccess, onLoadMoreLatestArticlesSuccess),
  on(filesActions.loadMoreLatestArticlesFailure, onLoadMoreLatestArticlesFailure),
  on(filesActions.search, onSearch),
  on(filesActions.searchSuccess, onSearchSuccess),
  on(filesActions.searchFailure, onSearchFailure),
  on(filesActions.clearSearch, onClearSearch),
  on(filesActions.loadPinnedArticles, onLoadPinnedArticles),
  on(filesActions.loadPinnedArticlesSuccess, onLoadPinnedArticlesSuccess),
  on(filesActions.loadPinnedArticlesFailure, onLoadPinnedArticlesFailure),
);
