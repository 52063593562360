import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mvp-keywords',
  templateUrl: './keywords.component.html',
})
export class KeywordsComponent implements OnInit {

  @Input() keywords: string[];

  constructor() { }

  ngOnInit() { }

}
