import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { compareVersions } from 'compare-versions';
import { App } from '@capacitor/app';

interface VersionResponse {
  version: string;
}

@Injectable({
  providedIn: 'root'
})

export class VersionCheckService {

  constructor(private http: HttpClient) { }

  getLatestVersion(): Observable<string> {
    return this.http.get<VersionResponse>(`${environment.apiUrl}/application/${environment.application}/version`)
      .pipe(
        map(response => response.version),
        catchError(this.handleError)
      );
  }

  getCurrentVersion(): Observable<string> {
    return from(App.getInfo()).pipe(
      map(info => info.version),
      map(version => version.replace(/-.+$/, ''))
    );
  }

  checkVersion(): Observable<boolean> {
    return this.getLatestVersion().pipe(
      switchMap(latestVersion =>
        this.getCurrentVersion().pipe(
          map(currentVersion => compareVersions(latestVersion, currentVersion) > 0)
        )
      ),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
