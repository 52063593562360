import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Chat } from 'src/app/services/chat.service';
import { Domain } from 'src/app/services/domain.service';
import { SocketService } from 'src/app/services/socket.service';
import { SOCKET_CONNECTION } from '../actions/socket.actions';
import { selectDomain, selectIsOperator } from '../selectors/auth.selectors';
import { selectChats } from '../selectors/chats.selectors';

@Injectable()
export class SocketEffects {

  newChatSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SOCKET_CONNECTION),
      concatLatestFrom(() => [
        this.store.select(selectChats),
        this.store.select(selectIsOperator),
        this.store.select(selectDomain),
      ]),
      tap(([, chats, isOperator, domain]: [unknown, Chat[], boolean, Domain]) => {
        [...chats].map((chat: Chat) => {
          this.socketService.io.emit('subscribe', { room: chat.uuid });
        });
        if (isOperator && domain) {
          this.socketService.io.emit('subscribe', { room: `${domain.uuid}/operators` });
        }
      })
    );
  }, {
    dispatch: false,
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private socketService: SocketService,
  ) { }
}
