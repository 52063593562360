import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface AppConfig {
  label: string;
  slug: string;
  ui_color_bg: string;
  ui_color_text: string;
  has_searchbar: boolean;
}

export interface ApiConfig {
  version: string;
  under_maintenance: string;
  app: AppConfig;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  apiConfig: ApiConfig;

  constructor(
    private httpClient: HttpClient
  ) { }

  loadConfiguration(): Promise<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/application/${environment.application}`)
      .toPromise()
      .then((config: ApiConfig) => {
        this.apiConfig = config;
        document.title = config.app.label;
      });
  }
}
