import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { AuthState } from '../reducers/auth.reducer';
import { SocketState } from '../reducers/socket.reducer';

export const selectSocketState = (state: AppState) => state.socket;

export const selectSocketConnectionStatus = createSelector(
  selectSocketState,
  (state: SocketState) => state.isConnected
);

export const selectIsConnectedToSocket = createSelector(
  selectSocketState,
  (state: SocketState) => state.isConnected
);

export const selectIsDisconnectedFromSocket = createSelector(
  selectSocketState,
  (state: SocketState) => !state.isConnected
);
