import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApplicationInterceptor } from './application.interceptor';
import { DomainInterceptor } from './domain.interceptor';
import { ErrorsInterceptor } from './errors.interceptor';
import { JwtInterceptor } from './jwt.interceptor';

export const InterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DomainInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
];
