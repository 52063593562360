import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'navigation-arrows',
  templateUrl: './navigation-arrows.component.html',
})
export class NavigationArrowsComponent implements OnInit {
  environment = environment;
  @Input() prevUuid?: string;
  @Input() nextUuid?: string;
  @Input() resourceType: string;
  @Input() sortDirection: string;

  currentQueryParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
  }

  navigateLeft() {
    if (this.nextUuid) {
      this.router.navigate([`${this.resourceType}/${this.nextUuid}`], {
        queryParams: this.currentQueryParams,
      });
    }
  }

  navigateRight() {
    if (this.prevUuid) {
      this.router.navigate([`${this.resourceType}/${this.prevUuid}`], {
        queryParams: this.currentQueryParams,
      });
    }
  }

  arrowColor() {
    return this.environment.application === 'tract'
      ? ' text-[#FFA500] border-[#FFA500] '
      : ' text-[#3D57F2] border-[#3D57F2] ';
  }
}
