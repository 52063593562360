import { Component, Input, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { FaqService } from 'src/app/services/faq.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Domain } from 'src/app/services/domain.service';
import { selectDomain } from 'src/app/store/selectors/auth.selectors';
export interface Stars {
  icon: string;
  rating: number;
  selected: boolean;
  background: string;
  textColor: string;
  altIconSrc: string;
}

@Component({
  selector: 'mvp-stars',
  templateUrl: './stars.component.html',
})
export class StarsComponent implements OnInit {
  @Input() type: string;
  @Input() uuid: string;

  environment = environment;

  displayText: string = "vos représentants"; // Default text

  stars: Stars[] = [
    {
      icon: 'frown',
      rating: 1,
      selected: false,
      background: 'bg-red-600',
      textColor: 'text-black',
      altIconSrc: 'assets/img/svg/frown.svg',
    },
    {
      icon: 'meh',
      rating: 3,
      selected: false,
      background: 'bg-yellow-500',
      textColor: 'text-black',
      altIconSrc: 'assets/img/svg/meh.svg',
    },
    {
      icon: 'smile',
      rating: 5,
      selected: false,
      background: 'bg-green-400',
      textColor: 'text-black',
      altIconSrc: 'assets/img/svg/smile.svg',
    },
  ];

  selected: Stars = null;
  hasRated = false;
  domain$: Observable<Domain>;

  constructor(
    private articleService: ArticleService,
    private questionService: FaqService,
    private store: Store,

  ) {
    this.domain$ = this.store.select(selectDomain);
  }

  ngOnInit() {
    this.domain$.subscribe(domain => {
      this.updateDisplayText(domain);
    });
  }

  select(index: number) {
    if (this.type !== 'article' && this.type !== 'question') {
      return false;
    }
    this.stars.forEach((star) => {
      star.selected = false;
    });
    this.stars[index].selected = true;
    this[`${this.type}Service`].rate(this.uuid, this.stars[index].rating);
    this.hasRated = true;
  }

  updateDisplayText(domain: Domain) {
    if (environment.application === "merlin") {
      this.displayText = "l'équipe Merlin";
    } else if (domain && domain.slug === "fo-sante") {
      this.displayText = "l'équipe Tract";
    } else {
      this.displayText = "vos représentants";
    }
  }
}
