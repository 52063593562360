import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Chat } from './chat.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  constructor(
    private httpClient: HttpClient,
    private socketService: SocketService,
  ) { }

  initiatedChats(): Observable<Chat[]> {
    return this.httpClient.get<Chat[]>(`${environment.apiUrl}/me/chats`).pipe(
      tap((chats: Chat[]) => {
        chats.map((chat: Chat) => {
          this.socketService.io.emit('subscribe', { room: chat.uuid });
        });
      })
    );
  }

  assignedChats(): Observable<Chat[]> {
    return this.httpClient.get<Chat[]>(`${environment.apiUrl}/chats/mine`).pipe(
      tap((chats: Chat[]) => {
        chats.map((chat: Chat) => {
          this.socketService.io.emit('subscribe', { room: chat.uuid });
        });
      })
    );
  }

  allChats(): Observable<Chat[]> {
    return this.httpClient.get<Chat[]>(`${environment.apiUrl}/chats/mines`).pipe(
      tap((chats: Chat[]) => {
        chats.map((chat: Chat) => {
          this.socketService.io.emit('subscribe', { room: chat.uuid });
        });
      })
    );
  }
}
