import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';
import { SetValueDirective } from './set-value.directive';

const MVP_DIRECTIVES = [
  TextareaAutoresizeDirective,
  SetValueDirective,
];

@NgModule({
  declarations: MVP_DIRECTIVES,
  imports: [
    CommonModule
  ],
  exports: MVP_DIRECTIVES
})
export class DirectivesModule { }
