import { createReducer, on } from '@ngrx/store';
import { Survey, SurveyOption } from 'src/app/services/surveys.service';
import {
  answerSurvey,
  answerSurveyFailure,
  answerSurveySuccess,
  loadSurveys,
  loadSurveysFailure,
  loadSurveysSuccess,
} from '../actions/survey.actions';

export interface SurveyState {
  surveys: Survey[];
  current: Survey;
  isLoading: boolean;
  error: any;
}

export const initialState: SurveyState = {
  surveys: [],
  current: null,
  isLoading: false,
  error: null,
};

const onLoading = (state: SurveyState): SurveyState => {
  return {
    ...state,
    isLoading: true,
  };
};

const onFailure = (state: SurveyState, { error }): SurveyState => {
  return {
    ...state,
    error,
    isLoading: false,
  };
};

const onSuccess = (state: SurveyState): SurveyState => {
  return {
    ...state,
    error: null,
    isLoading: false,
  };
};

const onLoadSurveysSuccess = (
  state: SurveyState,
  { surveys }: { surveys: Survey[] }
) => {
  surveys = surveys.map((survey: Survey) => {
    survey.options.map((option: SurveyOption) => {
      option.isSelected = survey.current_user_answers.some(
        (item: SurveyOption) => item.uuid === option.uuid
      );
      return option;
    });
    return survey;
  });
  return {
    ...state,
    surveys,
  };
};

const onAnswerSurveySuccess = (
  state: SurveyState,
  { options, survey }: { options: SurveyOption[]; survey: Survey }
) => {
  const current = state.surveys.find(
    (item: Survey) => item.uuid === survey.uuid
  );

  current.options.forEach((option) => {
    if (
      current.current_user_answers.some((answer) => answer.uuid === option.uuid)
    ) {
      option.answers_count--;
    }
  });

  current.current_user_answers = options;

  current.options.forEach((option) => {
    if (options.some((answer) => answer.uuid === option.uuid)) {
      option.answers_count++;
    }
  });

  return {
    ...state,
    surveys: state.surveys.map((item) =>
      item.uuid === survey.uuid ? current : item
    ),
  };
};

export const reducer = createReducer(
  initialState,
  on(loadSurveysSuccess, onLoadSurveysSuccess),
  on(answerSurveySuccess, onAnswerSurveySuccess),
  on(loadSurveys, answerSurvey, onLoading),
  on(loadSurveysSuccess, answerSurveySuccess, onSuccess),
  on(loadSurveysFailure, answerSurveyFailure, onFailure)
);
