import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
@Pipe({
  name: 'dateDistance'
})
export class DateDistancePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    if (!date) {return date;}
    return formatDistanceToNow(
      Date.parse(date.replace(/\s+/g, 'T')),
      {
        addSuffix: false,
        includeSeconds: true,
        locale: fr
      }
    );
  }

}
