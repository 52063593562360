import { createSelector } from '@ngrx/store';
import { User } from 'src/app/services/auth.service';
import { Chat } from 'src/app/services/chat.service';
import { AppState } from '..';
import { ChatsState } from '../reducers/chats.reducer';
import { selectUser } from './auth.selectors';

export const selectChatsState = (state: AppState) => state.chats;

export const selectChats = createSelector(
  selectChatsState,
  (state: ChatsState) => state.chats
);

export const selectChatsNewMessagesCounter = createSelector(
  selectChatsState,
  (state: ChatsState) => state.chats
    .map((chat: Chat) => chat.newMessages ? chat.newMessages.length : 0)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
);

export const selectCurrentChat = createSelector(
  selectChatsState,
  (state: ChatsState) => state.current
);

export const selectIsLoading = createSelector(
  selectChatsState,
  (state: ChatsState) => state.isLoading
);

export const selectOperators = createSelector(
  selectChatsState,
  selectUser,
  (state: ChatsState, user: User) => state.operators.filter((item: User) => item.uuid !== user.uuid)
);

export const selectError = createSelector(
  selectChatsState,
  (state: ChatsState) => state.error
);