import { createReducer, on } from '@ngrx/store';
import * as socketActions from 'src/app/store/actions/socket.actions';

export interface SocketState {
  isConnected: boolean;
}

export const initialState: SocketState = {
  isConnected: true,
};

const onSocketConnection = (state: SocketState) => ({
    ...state,
    isConnected: true,
  });

const onSocketDisconnection = (state: SocketState) => ({
    ...state,
    isConnected: false,
  });

export const reducer = createReducer(
  initialState,
  on(socketActions.socketConnection, onSocketConnection),
  on(socketActions.socketDisconnection, onSocketDisconnection),
);
