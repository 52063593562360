import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchResult } from 'src/app/services/search.service';
import {
  debounce,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { clearSearch, search } from 'src/app/store/actions/files.actions';
import { Observable, of, timer } from 'rxjs';
import {
  selectSearchIsLoading,
  selectSearchQuery,
  selectSearchResults,
} from 'src/app/store/selectors/files.selector';

@Component({
  selector: 'mvp-search-files',
  templateUrl: './search-files.component.html',
})
export class SearchFilesComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  form: FormGroup = this.formBuilder.group({
    input: ['', [Validators.required, Validators.minLength(3)]],
  });
  isLoading$: Observable<boolean>;
  searchResults$: Observable<SearchResult[]>;
  searchValue$: Observable<string>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store
  ) {
    this.searchResults$ = this.store.select(selectSearchResults);
    this.isLoading$ = this.store.select(selectSearchIsLoading);
    this.searchValue$ = this.store.select(selectSearchQuery);
  }

  ngOnInit() {
    this.form
      .get('input')
      .valueChanges.pipe(
        filter((value: string) => value.length >= 3 || value.length === 0),
        debounce(() => timer(1500)),
        distinctUntilChanged(),
        switchMap((query) => {
          if (query.length === 0) {
            return of(clearSearch());
          }
          return of(search({ query }));
        })
      )
      .subscribe((action) => {
        this.store.dispatch(action);
      });
  }

  resetSearchInput() {
    this.store.dispatch(clearSearch());
  }

  async goToResult(result: SearchResult) {
    switch (result.from) {
      case 'news':
        this.router.navigate([`article/${result.uuid}`]);
        break;
      case 'faq':
        this.router.navigate([`question/${result.uuid}`]);
        break;
      default:
        this.router.navigate([`question/${result.uuid}`]);
        break;
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    const inputElement = this.searchInput.nativeElement;
    if (
      event.target !== inputElement &&
      inputElement === document.activeElement
    ) {
      inputElement.blur();
    }
  }
}
