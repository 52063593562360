import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mvp-questions-skeleton',
  templateUrl: './questions-skeleton.component.html',
})
export class QuestionsSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
