import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'toFrenchDate'
})
export class ToFrenchDatePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    return format(new Date(date.replace(/\s+/g, 'T')), 'dd/MM/yyyy HH:mm', { locale: fr });
  }

}
