import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './auth.service';
import { Domain } from './domain.service';
import { SocketService } from './socket.service';

export interface Chat {
  domain: Domain;
  uuid: string;
  initiator: User;
  assignee: User;
  status: string;
  assignee_date: number;
  user_mark: number;
  request_at: Date;
  started_at: Date;
  ended_at: Date;
  created_at: Date;
  updated_at: Date;
  messages: Message[];
  newMessages: Message[];
  newMessagesCounter: number;
  discussingWith?: 'initiator'|'assignee';
}

export interface Message {
  content: string;
  attachment: MessageAttachment;
  extension: string;
  creator: any;
  request: Request;
  created_at: string;
}

export interface MessageAttachment {
  uuid: string;
  name: string;
  extension: string;
  created_at: string;
  updated_at: string;
}

export type ChatsListType = 'initiated' | 'assigned';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private httpClient: HttpClient,
    private socketService: SocketService,
    private store: Store,
  ) { }

  new(data: FormData): Observable<Chat> {
    return this.httpClient.post<Chat>(`${environment.apiUrl}/chats`, data).pipe(
      tap((chat: Chat) => {
        // this.socketService.io.emit('request:new', { domain: chat.domain , request: chat });
        // if (chat.status === 'pending') {
        // }
      })
    );
  }

  one(uuid: string): Observable<Chat> {
    return this.httpClient.get<Chat>(`${environment.apiUrl}/chats/${uuid}`);
  }

  // end(record: Chat): Observable<Chat> {
  //   return this.httpClient.delete<Chat>(`${environment.apiUrl}/requests/${record.uuid}`).pipe(
  //     tap((chat: Chat) => {
  //       if (record.status === 'pending') {
  //         this.store.dispatch(cancelChat({ chat }));
  //       }
  //       if (record.status === 'assigned') {
  //         this.store.dispatch(closeChat({ chat }));
  //       }
  //     })
  //   );
  // }

  sendMessage(chat: Chat, data: FormData): Observable<Message> {
    const headers = new HttpHeaders({
      Enctype: 'multipart/form-data',
    });
    return this.httpClient.post<Message>(`${environment.apiUrl}/chats/${chat.uuid}/messages`, data, { headers }).pipe(
      tap((message: Message) => {
        this.socketService.io.emit('chat:send', { message, request: chat });
      })
    );
  }

  operators(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.apiUrl}/chats/interlocutors`);
  }
}
