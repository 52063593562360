import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'toTime'
})
export class ToTimePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    return format(Date.parse(date), 'HH:mm');
  }

}
