import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Question } from 'src/app/services/question.service';
import { SearchResult } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';
// import { fromEvent } from 'rxjs';
// import { distinctUntilChanged, filter, map } from 'rxjs/operators';
@Component({
  selector: 'mvp-questions-list',
  templateUrl: './questions-list.component.html',
})
export class QuestionsListComponent implements OnInit {

  @ViewChild('list', { static: true }) list: ElementRef;

  @Input() questions: Array<Question | SearchResult>;
  @Input() toFullList: string;
  // @Output() loadMore = new EventEmitter();
  assetUrl: string = environment.assetUrl;
  activeCardIndex: number = 0;
  environment = environment;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.updateActiveChip();

    // Old logic to load more questions
    // fromEvent(this.list.nativeElement, 'scroll').pipe(
    //   map(({ target }: Event) => target),
    //   map(({ offsetWidth, scrollLeft, scrollWidth }: HTMLElement) => (offsetWidth + scrollLeft + 100) > scrollWidth),
    //   distinctUntilChanged(),
    //   filter(val => val)
    // )
    // .subscribe(() => {
    //   this.loadMore.emit();
    // });
  }

  async goToQuestion(question: Question | SearchResult) {
    const fromList = true;
    this.router.navigate([`question/${question.uuid}`], { queryParams: { fromList: fromList } });
  }

  scrollLeft() {
    this.list.nativeElement.scrollBy({ left: -300, behavior: 'smooth' });
  }

  scrollRight() {
    this.list.nativeElement.scrollBy({ left: 300, behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateActiveChip();
  }

  onScroll() {
    this.updateActiveChip();
  }

  updateActiveChip() {
    const listElement = this.list.nativeElement;
    const scrollLeft = listElement.scrollLeft;
    const viewportWidth = listElement.offsetWidth;

    let maxVisibleWidth = 0;
    let newActiveCardIndex = 0;

    const children = listElement.children;

    for (let i = 0; i < children.length; i++) {
      const card = children[i];
      const cardStart = card.offsetLeft - scrollLeft;
      const cardEnd = cardStart + card.offsetWidth;

      const visibleWidth =
        Math.min(cardEnd, viewportWidth) - Math.max(cardStart, 0);

      if (visibleWidth > maxVisibleWidth) {
        maxVisibleWidth = visibleWidth;
        newActiveCardIndex = i;
      }
    }

    this.activeCardIndex = newActiveCardIndex;
  }

  activeChipColor() {
    if (environment.application === 'tract') {
      return ' bg-[#FFA500]'
    }
    return ' bg-[#007bff]'
  }
}
