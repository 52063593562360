import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
@Pipe({
  name: 'readableDate'
})
export class ReadableDatePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    if (!date) {return date;}
    return format(new Date(date.replace(/\s+/g, 'T')), 'dd LLLL u', { locale: fr });
  }

}
