import { Pipe, PipeTransform } from '@angular/core';
import { format, formatRelative } from 'date-fns';
import { fr } from 'date-fns/locale';

const formatRelativeLocaleSimple = {
  lastWeek: "EEEE",
  yesterday: "'Hier'",
  today: "p",
  tomorrow: "'Demain à' p'",
  nextWeek: "EEEE 'prochain à' p",
  other: 'P',
};

const localeSimple = {
  ...fr,
  formatRelative: (token) => formatRelativeLocaleSimple[token],
};

const formatRelativeLocaleExtended = {
  lastWeek: "EEEE 'dernier à' p",
  yesterday: "'Hier à' p",
  today: "'Aujourd’hui à' p",
  tomorrow: "'Demain à' p'",
  nextWeek: "EEEE 'prochain à' p",
  other: 'P',
};

const localeExtended = {
  ...fr,
  formatRelative: (token) => formatRelativeLocaleExtended[token],
};

@Pipe({
  name: 'dateRelative'
})
export class DateRelativePipe implements PipeTransform {

  transform(date: string, type: string): unknown {
    if (!date) {return date;}
    return formatRelative(new Date(date.replace(/\s+/g, 'T')), new Date(), {
      locale: type === 'extended' ? localeExtended : localeSimple
    });
  }

}
