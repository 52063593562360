import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Network } from '@capacitor/network';
import { AlertService } from './alert.service';
import { environment } from 'src/environments/environment';
import { networkConnection, networkDisconnection } from '../store/actions/network.actions';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
    private store: Store,
    private alert: AlertService
  ) {

    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        this.store.dispatch(networkConnection());
        /*
        this.alert.success('Vous êtes de nouveau connecté à Internet', {
          icon: 'wifi',
          title: 'Connexion retrouvée'
        });
        */
      } else {
        this.store.dispatch(networkDisconnection());
        this.alert.danger('Vous n\’êtes plus connecté à Internet, certaines fonctionnalités ont été désactivées', {
          icon: 'alert-triangle',
          title: 'Connexion perdue'
        });
      }
    });
  }
}
