import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: use domain from store
    const promise = Preferences.get({ key: `${environment.application}-domain`});
    return from(promise).pipe(mergeMap(({ value }: GetResult) => {
      const cloneReq = this.addHeaders(request, value);
      return next.handle(cloneReq);
    }));
  }

  private addHeaders(request: HttpRequest<any>, value: string): HttpRequest<any> {
    const clone: HttpRequest<any>= request.clone({
      setHeaders: {
        Domain: value ?? ''
      }
    });
    return clone;
  }
}
