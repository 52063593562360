import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { DELETE_ACCOUNT, DELETE_ACCOUNT_SUCCESS, LOGOUT_SUCCESS } from './actions/auth.actions';
import * as fromChats from 'src/app/store/reducers/chats.reducer';
import * as fromAuth from 'src/app/store/reducers/auth.reducer';
import * as fromSocket from 'src/app/store/reducers/socket.reducer';
import * as fromFiles from 'src/app/store/reducers/files.reducer';
import * as fromGlobal from 'src/app/store/reducers/global.reducer';
import * as fromSurvey from 'src/app/store/reducers/survey.reducer';
import * as fromNetwork from 'src/app/store/reducers/network.reducer';
import * as fromDocuments from 'src/app/store/reducers/documents.reducer';

export interface AppState {
  chats: fromChats.ChatsState;
  auth: fromAuth.AuthState;
  socket: fromSocket.SocketState;
  files: fromFiles.FilesState;
  global: fromGlobal.GlobalState;
  survey: fromSurvey.SurveyState;
  network: fromNetwork.NetworkState;
  documents: fromDocuments.DocumentState;
}

export const reducers: ActionReducerMap<AppState> = {
  chats: fromChats.reducer,
  auth: fromAuth.reducer,
  socket: fromSocket.reducer,
  files: fromFiles.reducer,
  global: fromGlobal.reducer,
  survey: fromSurvey.reducer,
  network: fromNetwork.reducer,
  documents: fromDocuments.reducer,
};

const logout = (reducer: ActionReducer<AppState>): ActionReducer<AppState> => {
  return (state, action) => reducer([LOGOUT_SUCCESS, DELETE_ACCOUNT_SUCCESS].includes(action.type) ? undefined : state, action);
};

export const metaReducers: MetaReducer<AppState>[] = [logout];
