import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { FilesState, StoredArticles, StoredQuestions } from '../reducers/files.reducer';

export const selectFilesState = (state: AppState) => state.files;


export const selectPinnedArticles = createSelector(
  selectFilesState,
  (state: FilesState) => state.pinnedArticles.list,
);

export const selectPinnedArticlesIsLoading = createSelector(
  selectFilesState,
  (state: FilesState) => state.pinnedArticles.isLoading
);

export const selectMostViewedQuestions = createSelector(
  selectFilesState,
  (state: FilesState) => state.mostViewedQuestions,
);

export const selectMostViewedArticles = createSelector(
  selectFilesState,
  (state: FilesState) => state.mostViewedArticles,
);

export const selectLatestArticles = createSelector(
  selectFilesState,
  (state: FilesState) => state.latestArticles,
);

export const selectMostViewedQuestionsIsLoading = createSelector(
  selectMostViewedQuestions,
  (state: StoredQuestions) => state.isLoading,
);

export const selectMostViewedQuestionsNoMoreData = createSelector(
  selectMostViewedQuestions,
  (state: StoredQuestions) => state.noMoreData,
);

export const selectMostViewedArticlesIsLoading = createSelector(
  selectMostViewedArticles,
  (state: StoredArticles) => state.isLoading,
);

export const selectMostViewedArticlesNoMoreData = createSelector(
  selectMostViewedArticles,
  (state: StoredArticles) => state.noMoreData,
);

export const selectLatestArticlesIsLoading = createSelector(
  selectLatestArticles,
  (state: StoredArticles) => state.isLoading,
);

export const selectLatestArticlesNoMoreData = createSelector(
  selectLatestArticles,
  (state: StoredArticles) => state.noMoreData,
);

export const selectMostViewedQuestionsList = createSelector(
  selectMostViewedQuestions,
  (state: StoredQuestions) => state.list,
);

export const selectMostViewedQuestionsQueries = createSelector(
  selectMostViewedQuestions,
  (state: StoredQuestions) => state.queries,
);

export const selectMostViewedArticlesList = createSelector(
  selectMostViewedArticles,
  (state: StoredArticles) => state.list,
);

export const selectMostViewedArticlesQueries = createSelector(
  selectMostViewedArticles,
  (state: StoredArticles) => state.queries,
);

export const selectLatestArticlesList = createSelector(
  selectLatestArticles,
  (state: StoredArticles) => state.list,
);

export const selectLatestArticlesQueries = createSelector(
  selectLatestArticles,
  (state: StoredArticles) => state.queries,
);

export const selectSearchResults = createSelector(
  selectFilesState,
  (state: FilesState) => state.search.list,
);

export const selectSearchIsLoading = createSelector(
  selectFilesState,
  (state: FilesState) => state.search.isLoading,
);

export const selectSearchQuery = createSelector(
  selectFilesState,
  (state: FilesState) => state.search.query,
);
