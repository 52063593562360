import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { NetworkState } from '../reducers/network.reducer';

export const selectNetworkState = (state: AppState) => state.network;

export const selectNetworkConnectionStatus = createSelector(
  selectNetworkState,
  (state: NetworkState) => state.isConnected
);

export const selectIsConnectedToNetwork = createSelector(
  selectNetworkState,
  (state: NetworkState) => state.isConnected
);

export const selectIsDisconnectedFromNetwork = createSelector(
  selectNetworkState,
  (state: NetworkState) => !state.isConnected
);
