import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from './article.service';
import { Question } from './question.service';

export interface SearchResult {
  uuid: string;
  title: string;
  response: string;
  keywords: Array<string>;
  is_enabled: boolean;
  thumbnail: string;
  from: 'faq' | 'news';
  type: string;
  category: string;
  created_at?: string;
  publication_date?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  search({ query, filter }: {
    query: string;
    filter?: 'faq' | 'news';
  }): Observable<Array<SearchResult>> {
    const url = `${environment.apiUrl}/elasticsearch/search?`;
    // if (filter) {
    //   url += `filter=${filter}`;
    // }
    return this.httpClient.post<Array<SearchResult>>(url, { query });
  }

  autocomplete(query: string): Observable<Array<string>> {
    return this.httpClient.post<Array<string>>(`${environment.apiUrl}/elasticsearch/autocomplete`, { query });
  }
}
