import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Preferences, GetResult } from '@capacitor/preferences';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { from, of, pipe } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
export interface Credentials {
  email: string;
  password: string;
  password_confirmation?: string;
  deviceUuid?: string;
  device?: any;
  fcmToken?: string;
}

export interface AuthResponse {
  token: string;
  expires_at: Date;
  token_type: string;
  user: User;
}

export interface User {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  birth_day: string;
  zip: string;
  city: string;
  domains: any;
  address: string;
  stakeholder?: string;
  sector?: string;
  business_name: string;
  is_verified: string;
  stripe_id: string;
  card_brand: string;
  card_last_four: string;
  trials_end_at: string;
  avatar: string;
  notifications: any;
  fcm_token: string;
  organization?: string;
  is_connected?: boolean;
  can_use_chat: boolean;
}

export interface Device {
  uuid: string;
  fcmToken: string;
  isPushEnabled: boolean;
  infos: DeviceInfo;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  login(credentials: Credentials) {
    return this.httpClient.post(`${environment.apiUrl}/auth/login`, credentials);
  }

  logout() {
    return from(Device.getId()).pipe(
      mergeMap((deviceId: DeviceId) => {
        return this.httpClient.post(`${environment.apiUrl}/auth/logout`, {
          deviceUuid: deviceId.identifier
        }).pipe(
          tap(() => {
            Preferences.remove({ key: `${environment.application}-jwt` });
            Preferences.remove({ key: `${environment.application}-domain` });
          })
        );
      })
    );
  }

  getEmail(): Promise<GetResult> {
    return Preferences.get({
      key: `${environment.application}-email`
    });
  }

  createPassword(credentials: Credentials) {
    return this.httpClient.post(`${environment.apiUrl}/auth/password`, { ...credentials });
  }

  checkEmail(email: string) {
    return this.httpClient.post(`${environment.apiUrl}/auth/check-email`, { email });
  }

  forgotPassword(email: string) {
    return this.httpClient.post(`${environment.apiUrl}/auth/forgot-password`, { email });
  }

  profile() {
    return this.httpClient.get(`${environment.apiUrl}/me/profile`);
  }

  domain() {
    return this.httpClient.get(`${environment.apiUrl}/me/domain`);
  }

  uploadAvatar(file: File) {
    const formData = new FormData();
    formData.append('avatar', file);
    return this.httpClient.post(`${environment.apiUrl}/me/upload`, formData);
  }

  updateUser(data: any) {
    return this.httpClient.put(`${environment.apiUrl}/me`, data);
  }

  getRoles() {
    return this.httpClient.get(`${environment.apiUrl}/me/roles`);
  }

  toggleNotifications(data: { news?: boolean, surveys?: boolean }) {
    return this.httpClient.put(`${environment.apiUrl}/me/notifications`, data);
  }

  changePassword(data: {
    current_password: string;
    password: string;
    password_confirmation: string;
  }) {
    return this.httpClient.post(`${environment.apiUrl}/auth/change-password`, data);
  }

  devices() {
    return this.httpClient.get(`${environment.apiUrl}/me/devices`);
  }

  device(uuid: string) {
    return this.httpClient.get(`${environment.apiUrl}/me/device/${uuid}`);
  }

  saveDevice(device: Device) {
    return this.httpClient.post(`${environment.apiUrl}/me/devices`, device);
  }

  resendVerificationEmail(email: string) {
    return this.httpClient.post(`${environment.apiUrl}/auth/resend-verification-email`, {email});
  }

  deleteAccount() {
    return this.httpClient.delete(`${environment.apiUrl}/me/account`);
  }
}
