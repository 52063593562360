import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-avatar',
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnInit {

  @Input() user: User;
  assetUrl: string = environment.assetUrl;

  constructor() { }

  ngOnInit() {}

  firstLetter(word: string) {
    if (!word) {
      return '';
    }
    return word.charAt(0).toUpperCase();
  }
}
