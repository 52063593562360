import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/services/auth.service';
import { Chat, ChatsListType, Message } from 'src/app/services/chat.service';

export const LOAD_CHATS = '[Chats] Load Chats';
export const LOAD_CHATS_SUCCESS = '[Chats] Load Chats Success';
export const LOAD_CHATS_FAILURE = '[Chats] Load Chats Failure';

export const LOAD_CHAT = '[Chats] Load Chat';
export const LOAD_CHAT_SUCCESS = '[Chats] Load Chat Success';
export const LOAD_CHAT_FAILURE = '[Chats] Load Chat Failure';

export const SEND_MESSAGE = '[Chats] Send Message';
export const SEND_MESSAGE_SUCCESS = '[Chats] Send Message Success';
export const SEND_MESSAGE_FAILURE = '[Chats] Send Message Failure';

export const LOAD_OPERATORS = '[Chats] Load Operators';
export const LOAD_OPERATORS_SUCCESS = '[Chats] Load Operators Success';
export const LOAD_OPERATORS_FAILURE = '[Chats] Load Operators Failure';

export const NEW_CHAT = '[Chats] New Chat';
export const NEW_CHAT_SUCCESS = '[Chats] New Chat Success';
export const NEW_CHAT_FAILURE = '[Chats] New Chat Failure';

export const RECEIVED_MESSAGE = '[Chats] Received Message';
export const RECEIVED_CHAT = '[Chats] Received Chat';
export const UNSELECT_CHAT = '[Chats] Unselect Chat';
export const CLEAR_ERROR = '[Chats] Clear Error';
export const SET_CONNECTED_OPERATORS = '[Chats] Set Connected Operators';
export const SET_SELECTED_LIST = '[Chats] Set Selected List';

export const loadChats = createAction(
  LOAD_CHATS,
);

export const loadChatsSuccess = createAction(
  LOAD_CHATS_SUCCESS,
  props<{
    chats: Chat[];
  }>()
);

export const loadChatsFailure = createAction(
  LOAD_CHATS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadChat = createAction(
  LOAD_CHAT,
  props<{
    uuid: string;
  }>()
);

export const loadChatSuccess = createAction(
  LOAD_CHAT_SUCCESS,
  props<{
    chat: Chat;
    user: User;
  }>()
);

export const loadChatFailure = createAction(
  LOAD_CHAT_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const sendMessage = createAction(
  SEND_MESSAGE,
  props<{
    data: FormData;
  }>()
);

export const sendMessageSuccess = createAction(
  SEND_MESSAGE_SUCCESS,
  props<{
    message: Message;
  }>()
);

export const sendMessageFailure = createAction(
  SEND_MESSAGE_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const loadOperators = createAction(
  LOAD_OPERATORS,
);

export const loadOperatorsSuccess = createAction(
  LOAD_OPERATORS_SUCCESS,
  props<{
    operators: User[];
  }>()
);

export const loadOperatorsFailure = createAction(
  LOAD_OPERATORS_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const newChat = createAction(
  NEW_CHAT,
  props<{
    data: FormData;
  }>()
);

export const newChatSuccess = createAction(
  NEW_CHAT_SUCCESS,
  props<{
    chat: Chat;
  }>()
);

export const newChatFailure = createAction(
  NEW_CHAT_FAILURE,
  props<{
    error: any | HttpErrorResponse;
  }>()
);

export const receivedMessage = createAction(
  RECEIVED_MESSAGE,
  props<{
    message: Message;
    chat: Chat;
  }>()
);

export const receivedChat = createAction(
  RECEIVED_CHAT,
  props<{
    chat: Chat;
  }>()
);

export const unselectChat = createAction(
  UNSELECT_CHAT
);

export const clearError = createAction(
  CLEAR_ERROR,
);

export const setConnectedOperators = createAction(
  SET_CONNECTED_OPERATORS,
  props<{
    list: Array<string>;
  }>()
);

export const setSelectedList = createAction(
  SET_SELECTED_LIST,
  props<{
    list: ChatsListType;
  }>()
);
