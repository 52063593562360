import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mvp-password-input',
  templateUrl: './password-input.component.html',
})
export class PasswordInputComponent implements OnInit {

  @ViewChild('password') password: ElementRef;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() form: FormGroup;
  isShown = false;

  constructor() { }

  ngOnInit() {}

  toggleVisiblity() {
    if (this.isShown) {
      this.password.nativeElement.setAttribute('type', 'password');
    } else {
      this.password.nativeElement.setAttribute('type', 'text');
    }
    this.isShown = !this.isShown;
  }
}
