import { Component, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'back-button',
  templateUrl: 'back-button.component.html',
})
export class BackButtonComponent implements OnInit {
  environment = environment;

  constructor(
    private router: Router, 
    private navigationService: NavigationService
  ) {}

  ngOnInit() {}

  navigateBack() {
    const orignalUrl = this.navigationService.getOriginalUrl();

    if (this.router.url.includes('/list')) {
      this.router.navigateByUrl('/news')
    } else {
      this.router.navigateByUrl(orignalUrl);
    }
  }
}
