import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectChatsNewMessagesCounter } from 'src/app/store/selectors/chats.selectors';
import { Observable } from 'rxjs';
import { Domain } from 'src/app/services/domain.service';
import { selectDomain } from 'src/app/store/selectors/auth.selectors';
import { selectNewSurveysCount } from 'src/app/store/selectors/survey.selectors';
import { selectIsDisconnectedFromNetwork } from 'src/app/store/selectors/network.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mvp-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {

  currentRoute: string;
  environment = environment;
  newMessages$: Observable<number>;
  newSurveysCount$: Observable<number>;
  isDisconnectedFromNetwork$: Observable<boolean>;
  domain$: Observable<Domain>;

  constructor(
    private router: Router,
    private store: Store,
  ) {
    // set currentRoute
    this.currentRoute = this.router.url;
    this.isDisconnectedFromNetwork$ = this.store.select(selectIsDisconnectedFromNetwork);
    // events on Router Update
    router.events.subscribe((link) => {
      // get current url path
      if (link instanceof NavigationEnd) {
        this.currentRoute = link.url;
      }
    });
    this.newMessages$ = this.store.select(selectChatsNewMessagesCounter);
    this.domain$ = this.store.select(selectDomain);
    this.newSurveysCount$ = this.store.select(selectNewSurveysCount);
  }

  ngOnInit() {
    //
  }

  isCurrent(url: string) {
    return url === this.currentRoute;
  }
}
