import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { Chat, ChatsListType } from 'src/app/services/chat.service';
import { loadChat, loadChats } from '../actions/chats.actions';
import {
  loadLatestArticles,
  loadMostViewedArticles,
  loadMostViewedQuestions,
  loadPinnedArticles,
} from '../actions/files.actions';
import { CHATS_PAGE_INIT, FROM_FOREGROUND, NEWS_PAGE_INIT } from '../actions/events.actions';
import { noOpAction } from '../actions/global.actions';
import { selectCurrentChat } from '../selectors/chats.selectors';

@Injectable()
export class EventsEffects {
  reloadCurrentChatFromForeground$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
      ),
      concatLatestFrom(() => [
        this.store.select(selectCurrentChat),
      ]),
      map(([, chat]: [unknown, Chat]) => {
        if (!chat) {
          return noOpAction();
        }
        if (chat) {
          return loadChat({ uuid: chat.uuid });
        }
      }),
    );
  });

  loadPinnedArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
        NEWS_PAGE_INIT,
      ),
      map(() => loadPinnedArticles()),
    );
  });

  loadLatestArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
        NEWS_PAGE_INIT,
      ),
      map(() => loadLatestArticles()),
    );
  });

  loadMostViewedArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
        NEWS_PAGE_INIT,
      ),
      map(() => loadMostViewedArticles()),
    );
  });

  loadMostViewedQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
        NEWS_PAGE_INIT,
      ),
      map(() => loadMostViewedQuestions()),
    );
  });

  loadInitiatedChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FROM_FOREGROUND,
      ),
      map(() => loadChats())
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
  ) { }
}
