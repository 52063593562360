import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './auth.service';
import { Domain } from './domain.service';
import { File } from './interface';

export interface Article {
  uuid: string;
  title: string;
  body: string;
  keywords: Array<string>;
  document: string;
  thumbnail: string;
  is_enabled: boolean;
  is_notified: boolean;
  publication_date: string;
  created_at: string;
  updated_at: string;
  writer: User;
  documents: Array<File>;
  prevUuid?: string;
  nextUuid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  list({ domain, page, sort, pageSize = 5 }: {
    domain?: Domain;
    page?: number;
    sort?: string;
    pageSize?: number;

  }) {
    let url = `${environment.apiUrl}/articles?`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (page) {
      url += `&page[number]=${page}&page[size]=${pageSize}`
    }

    return this.httpClient.get<Article[]>(url);
  }

  getCount(): Observable<number> {
    let url = `${environment.apiUrl}/articles/count`;
    return this.httpClient.get<number>(url);
  }

  one(uuid: string, sort?: string, direction?: string): Observable<Article> {
    let params = new HttpParams();
    if (sort) {
      params = params.append('sort', sort);
    }
    if (direction) {
      params = params.append('direction', direction);
    }

    return this.httpClient.get<Article>(`${environment.apiUrl}/article/${uuid}`, { params });
  }


  consult(uuid: string) {
    return this.httpClient.post(`${environment.apiUrl}/article/${uuid}/consult`, {}).toPromise();
  }

  rate(uuid: string, rating: number) {
    return this.httpClient.post(`${environment.apiUrl}/article/${uuid}/rate`, { rating }).toPromise();
  }

  pinned() {
    let url = `${environment.apiUrl}/articles/pinned`;
    return this.httpClient.get<Article[]>(url);
  }
}
