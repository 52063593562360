import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store';
import { ChatsEffects } from './store/effects/chats.effect';
import { InterceptorProviders } from './interceptors/interceptors';
import { AuthEffects } from './store/effects/auth.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ComponentsModule } from './components/components.module';
import { IconsModule } from './icons.module';
import { FilesEffects } from './store/effects/files.effects';
import { GlobalEffects } from './store/effects/global.effects';
import { metaReducers } from './store';
import { EventsEffects } from './store/effects/events.effects';
import { SocketEffects } from './store/effects/socket.effects';
import { SurveyEffects } from './store/effects/survey.effects';
import { ConfigService } from './services/config.service';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    UpdateModalComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers,
    }),
    EffectsModule.forRoot([
      ChatsEffects,
      AuthEffects,
      FilesEffects,
      GlobalEffects,
      EventsEffects,
      SocketEffects,
      SurveyEffects,
    ]),
    ReactiveFormsModule,
    FormsModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ComponentsModule,
    IconsModule
  ],
  providers: [
    InterceptorProviders,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) =>
        () => configService.loadConfiguration(),
      deps: [ConfigService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
