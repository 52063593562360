import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/services/auth.service';
import { Chat, ChatsListType, Message } from 'src/app/services/chat.service';
import * as chatsActions from 'src/app/store/actions/chats.actions';
import { takeChatAndPlaceItFirst } from 'src/app/utils';

export interface ChatsState {
  chats: Chat[];
  current: Chat;
  isLoading: boolean;
  error: any;
  operators: User[];
  connectedOperators: Array<string>;
}

export const initialState: ChatsState = {
  chats: [],
  current: null,
  isLoading: false,
  error: null,
  operators: [],
  connectedOperators: [],
};

const onLoading = (state: ChatsState): ChatsState => {
  return {
    ...state,
    isLoading: true,
  };
};

const onFailure = (state: ChatsState, { error }): ChatsState => {
  return {
    ...state,
    error,
    isLoading: false,
  };
};

const onSuccess = (state: ChatsState): ChatsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
  };
};

const onLoadChatsSuccess = ( state: ChatsState, { chats }: { chats: Chat[] }): ChatsState => {
  return {
    ...state,
    chats,
  };
};

const onReceivedMessage = (state: ChatsState, { message, chat }: { message: Message; chat: Chat }): ChatsState => {
  if (chat.uuid === state.current?.uuid) {
    return {
      ...state,
      current: {
        ...state.current,
        messages: [...state.current.messages, message]
      }
    };
  }

  return {
    ...state,
    chats: takeChatAndPlaceItFirst(state.chats, chat).map((item: Chat) => {
      if (item.uuid === chat.uuid) {
        item.newMessages = item.newMessages ? [...item.newMessages, message] : [message];
      }
      return item;
    }),
  };
};

const onUnselectChat = (state: ChatsState): ChatsState => {
  return {
    ...state,
    current: null,
  };
};

const onSendNewMessageSuccess = (state: ChatsState, { message }: { message: Message}): ChatsState => {
  const current = { ...state.current };
  current.messages.push(message);
  // const chats = state.chats.filter((chat: Chat) => chat.uuid !== current.uuid)
  console.log(takeChatAndPlaceItFirst(state.chats, current))
  return {
    ...state,
    current,
    chats: takeChatAndPlaceItFirst(state.chats, current),
  };
};

const onLoadOperatorsSuccess = (state: ChatsState, { operators }: { operators: User[] }): ChatsState => {
  operators = operators.map((operator: User) => {
    operator.is_connected = state.connectedOperators.includes(operator.uuid) ? true : false;
    return operator;
  });
  return {
    ...state,
    operators,
  };
};

const onClearError = (state: ChatsState): ChatsState => {
  return {
    ...state,
    error: null,
  };
};

const setConnectedOperators = (state: ChatsState, { list }: { list: string[]}): ChatsState => {
  const operators = state.operators.map((operator: User) => {
    operator.is_connected = list.includes(operator.uuid);
    return operator;
  });

  return {
    ...state,
    connectedOperators: list,
    operators,
  };
};

const onReceivedChat = (state: ChatsState, { chat }): ChatsState => {
  console.log('new chat', chat)
  return {
    ...state,
    chats: [
      {
        ...chat,
        newMessages: chat.messages,
      },
      ...state.chats
    ],
  };
};

const onLoadChatSuccess = (state: ChatsState, { chat, user }: { chat: Chat, user: User }): ChatsState => {
  chat.discussingWith = chat.assignee.uuid === user.uuid ? 'initiator' : 'assignee';
  const chats = state.chats.map((item: Chat) => {
    if (item.uuid === chat.uuid) {
      item.newMessages = null;
    }
    return item
  })
  return {
    ...state,
    current: chat,
    chats,
  };
};

const onNewChatSuccess = (state: ChatsState, { chat }: { chat: Chat }): ChatsState => {
  return {
    ...state,
    chats: [
      chat,
      ...state.chats
    ],
    current: chat,
  };
};

export const reducer = createReducer(
  initialState,
  on(chatsActions.loadChatsSuccess, onLoadChatsSuccess),
  on(chatsActions.loadChatSuccess, onLoadChatSuccess),
  on(chatsActions.newChatSuccess, onNewChatSuccess),
  on(chatsActions.receivedMessage, onReceivedMessage),
  on(chatsActions.unselectChat, onUnselectChat),
  on(chatsActions.sendMessageSuccess, onSendNewMessageSuccess),
  on(chatsActions.loadOperatorsSuccess, onLoadOperatorsSuccess),
  on(chatsActions.clearError, onClearError),
  on(chatsActions.setConnectedOperators, setConnectedOperators),
  on(chatsActions.receivedChat, onReceivedChat),
  on(
    chatsActions.sendMessage,
    chatsActions.loadChat,
    chatsActions.loadOperators,
    chatsActions.newChat,
    onLoading,
  ),
  on(
    chatsActions.sendMessageSuccess,
    chatsActions.loadChatSuccess,
    chatsActions.loadOperatorsSuccess,
    chatsActions.newChatSuccess,
    onSuccess,
  ),
  on(
    chatsActions.sendMessageFailure,
    chatsActions.loadChatFailure,
    chatsActions.loadOperatorsFailure,
    chatsActions.newChatFailure,
    onFailure,
  ),
);

