import { createAction, props } from '@ngrx/store';

export const FROM_FOREGROUND = '[Events] From Foreground';
export const NEWS_PAGE_INIT = '[Events] News Page Init';
export const CHATS_PAGE_INIT = '[Events] Chats Page Init';

export const fromForeground = createAction(
  FROM_FOREGROUND,
);

export const newsPageInit = createAction(
  NEWS_PAGE_INIT,
);

export const chatsPageInit = createAction(
  CHATS_PAGE_INIT,
);

