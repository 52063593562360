import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert, AlertService, AlertTypes } from 'src/app/services/alert.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'mvp-alert',
  templateUrl: './alert.component.html',
  animations: [
    trigger('slideIn', [
      state('*', style({
        transform: 'translateY(0)',
        opacity: 1,
        filter: 'blur(0) saturate(100%)',
      })),
      state('void', style({
        transform: 'translateY(-100%)',
        opacity: 0,
        filter: 'blur(2px) saturate(50%)',
      })),
      transition('void => *', animate('.3s ease-in-out'))
    ]),
  ]
})
export class AlertComponent implements OnInit {

  alerts: Alert[] = [];

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.alertService.onAlert().subscribe((alert: Alert) => {
      this.alerts.push(alert);
      setTimeout(() => this.removeAlert(alert), 4000);
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  goToAction(alert: Alert) {
    if (alert?.route) {
      this.router.navigate([alert.route]);
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  getClass(alert: Alert) {
    switch (alert.alertType) {
      case AlertTypes.SUCCESS:
        return 'text-green-500';
      case AlertTypes.WARNING:
        return 'text-yellow-500';
      case AlertTypes.DANGER:
        return 'text-red-500';
      case AlertTypes.INFO:
        return 'text-primary-500';
      case AlertTypes.PUSH:
        return 'text-primary-500';
      default:
        return 'text-black';
    }
  }

}
