import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/services/auth.service';
import { Chat } from 'src/app/services/chat.service';
import { selectUser } from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: 'mvp-chats-list',
  templateUrl: './chats-list.component.html',
})
export class ChatsListComponent implements OnInit {

  @Input() chats: Chat[];
  user$: Observable<User>;

  constructor(
    private router: Router,
    private store: Store,
  ) {
    this.user$ = this.store.select(selectUser);
  }

  ngOnInit() {
    this.user$.subscribe((user: User) => {
      this.chats.map((chat: Chat) => {
        chat.discussingWith = chat.assignee.uuid === user.uuid ? 'initiator' : 'assignee';
        return chat
      })
    })
  }

  goToChat(chat: Chat) {
    this.router.navigate([`/chat/${chat.uuid}`]);
  }
}
