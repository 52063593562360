import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export class Alert {
  id: string;
  title?: string;
  message: string;
  alertType: 'success' | 'error' | 'warning' | 'info';
  route: string;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export class AlertTypes {
  public static SUCCESS = 'success';
  public static DANGER = 'danger';
  public static WARNING = 'warning';
  public static INFO = 'info';
  public static PUSH = 'push';
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new BehaviorSubject<Alert>(null);

  constructor() { }

  onAlert(): Observable<Alert> {
    return this.subject.asObservable().pipe(
      filter(val => val !== null),
    );
  }

  success(message: string, options?: any) {
    this.alert(new Alert({ ...options, alertType: AlertTypes.SUCCESS, message }));
  }

  danger(message: string, options?: any) {
    this.alert(new Alert({ ...options, alertType: AlertTypes.DANGER, message }));
  }

  info(message: string, options?: any) {
    this.alert(new Alert({ ...options, alertType: AlertTypes.INFO, message }));
  }

  warn(message: string, options?: any) {
    this.alert(new Alert({ ...options, alertType: AlertTypes.WARNING, message }));
  }

  push(message: string, options?: any) {
    this.alert(new Alert({ ...options, alertType: AlertTypes.PUSH, message }));
  }

  alert(alert: Alert) {
    this.subject.next(alert);
  }
}
