import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { socketConnection, socketDisconnection } from '../store/actions/socket.actions';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public io: any;

  constructor(
    private store: Store,
  ) {
    this.io = io(environment.socketUrl);
    this.io.on('connect', () => {
      this.store.dispatch(socketConnection());
    });
    this.io.on('error', (reason: string) => {
      this.store.dispatch(socketDisconnection());
    });
    this.io.on('connect_error', (reason: Error) => {
      this.store.dispatch(socketDisconnection());
      setTimeout(() => {
        this.io.connect();
      }, 1000);
    });
    this.io.on('disconnect', (reason: string) => {
      if (reason === 'io server disconnect') {
        this.io.connect();
      }
      this.store.dispatch(socketDisconnection());
    });
  }
}
