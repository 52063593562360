import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import {
  loadLatestArticlesFailure,
  loadLatestArticlesSuccess,
  loadMoreLatestArticlesFailure,
  loadMoreLatestArticlesSuccess,
  loadMoreMostViewedArticlesFailure,
  loadMoreMostViewedArticlesSuccess,
  loadMoreMostViewedQuestionsFailure,
  loadMoreMostViewedQuestionsSuccess,
  loadMostViewedArticlesFailure,
  loadMostViewedArticlesSuccess,
  loadMostViewedQuestionsFailure,
  loadMostViewedQuestionsSuccess,
  LOAD_LATEST_ARTICLES,
  LOAD_MORE_LATEST_ARTICLES,
  LOAD_MORE_MOST_VIEWED_ARTICLES,
  LOAD_MORE_MOST_VIEWED_QUESTIONS,
  LOAD_MOST_VIEWED_ARTICLES,
  LOAD_MOST_VIEWED_QUESTIONS,
  SEARCH,
  searchFailure,
  searchSuccess,
  loadPinnedArticlesSuccess,
  loadPinnedArticlesFailure,
  LOAD_PINNED_ARTICLES,
} from '../actions/files.actions';
import { Question, QuestionService } from 'src/app/services/question.service';
import { Store } from '@ngrx/store';
import { Article, ArticleService } from 'src/app/services/article.service';
import { SearchResult, SearchService } from 'src/app/services/search.service';
import {
  selectLatestArticlesNoMoreData,
  selectLatestArticlesQueries,
  selectMostViewedArticlesNoMoreData,
  selectMostViewedArticlesQueries,
  selectMostViewedQuestionsNoMoreData,
  selectMostViewedQuestionsQueries
} from '../selectors/files.selector';
import { FilesQueries } from '../reducers/files.reducer';

@Injectable()
export class FilesEffects {

  /***LOAD PINNED ARTICLES */
  loadPinnedArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_PINNED_ARTICLES),
      exhaustMap(() => {
        return this.articleService.pinned().pipe(
          map((articles: Article[]) => loadPinnedArticlesSuccess({ articles })),
          catchError(error => of(loadPinnedArticlesFailure({ error })))
        );
      })
    );
  });


  /********** MOST VIEWED QUESTIONS **********************/
  loadMostViewedQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_MOST_VIEWED_QUESTIONS),
      concatLatestFrom(() => this.store.select(selectMostViewedQuestionsQueries)),
      exhaustMap(([, queries]: any) => {
        return this.questionService.list({ ...queries, page: 1}).pipe(
          map((questions: Question[]) => loadMostViewedQuestionsSuccess({ questions })),
          catchError(error => of(loadMostViewedQuestionsFailure({ error })))
        );
      })
    );
  });

  loadMoreMostViewedQuestions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_MORE_MOST_VIEWED_QUESTIONS),
      concatLatestFrom(() => this.store.select(selectMostViewedQuestionsNoMoreData)),
      filter(([, noMoreData]) => !noMoreData),
      concatLatestFrom(() => this.store.select(selectMostViewedQuestionsQueries)),
      map(([, queries]) => queries),
      exhaustMap((queries: FilesQueries) => {
        return this.questionService.list({ ...queries, page: (queries.page + 1)}).pipe(
          map((questions: Question[]) => loadMoreMostViewedQuestionsSuccess({ questions })),
          catchError(error => of(loadMoreMostViewedQuestionsFailure({ error })))
        );
      })
    );
  });

  /********** MOST VIEWED ARTICLES **********************/
  loadMostViewedArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_MOST_VIEWED_ARTICLES),
      concatLatestFrom(() => this.store.select(selectMostViewedArticlesQueries)),
      exhaustMap(([, queries]: any) => {
        return this.articleService.list({ ...queries, page: 1 }).pipe(
          map((articles: Article[]) => loadMostViewedArticlesSuccess({ articles })),
          catchError(error => of(loadMostViewedArticlesFailure({ error })))
        );
      })
    );
  });

  loadMoreMostViewedArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_MORE_MOST_VIEWED_ARTICLES),
      concatLatestFrom(() => this.store.select(selectMostViewedArticlesNoMoreData)),
      filter(([, noMoreData]) => !noMoreData),
      concatLatestFrom(() => this.store.select(selectMostViewedArticlesQueries)),
      map(([, queries]) => queries),
      exhaustMap((queries: FilesQueries) => {
        return this.articleService.list({ ...queries, page: (queries.page + 1)}).pipe(
          map((articles: Article[]) => loadMoreMostViewedArticlesSuccess({ articles })),
          catchError(error => of(loadMoreMostViewedArticlesFailure({ error })))
        );
      })
    );
  });

  /********** LATEST ARTICLES **********************/
  loadLatestArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_LATEST_ARTICLES),
      concatLatestFrom(() => this.store.select(selectLatestArticlesQueries)),
      exhaustMap(([, queries]: any) => {
        return this.articleService.list({ ...queries, page: 1 }).pipe(
          map((articles: Article[]) => loadLatestArticlesSuccess({ articles })),
          catchError(error => of(loadLatestArticlesFailure({ error })))
        );
      })
    );
  });

  loadMoreLatestArticles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_MORE_LATEST_ARTICLES),
      concatLatestFrom(() => this.store.select(selectLatestArticlesNoMoreData)),
      filter(([, noMoreData]) => !noMoreData),
      concatLatestFrom(() => this.store.select(selectLatestArticlesQueries)),
      map(([, queries]) => queries),
      exhaustMap((queries: FilesQueries) => {
        return this.articleService.list({ ...queries, page: (queries.page + 1)}).pipe(
          map((articles: Article[]) => loadMoreLatestArticlesSuccess({ articles })),
          catchError(error => of(loadMoreLatestArticlesFailure({ error })))
        );
      })
    );
  });

  /********** SEARCH **********************/
  search$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SEARCH),
      exhaustMap(({ query }) => {
        return this.searchService.search({ query }).pipe(
          map((results: SearchResult[]) => searchSuccess({ results })),
          catchError(error => of(searchFailure({ error })))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private questionService: QuestionService,
    private articleService: ArticleService,
    private store: Store,
    private searchService: SearchService,
  ) { }
}
